import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import "../css/excelReader.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
// import DataTable from "react-data-table-component";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { BarChart } from "@mui/x-charts/BarChart";
import { MenuItem, Select, FormControl, InputLabel, Grid, Typography, Button } from "@mui/material";

function ExcelReader() {
  const [filePath, setFilePath] = useState("");
  const [rows, setRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAtmId, setSelectedAtmId] = useState("");
  // const [logs, setLogs] = useState([]);
  // const [error, setError] = useState(null);
  const [isTrue, setisTrue] = useState(false);
  const [profit, setisProfit] = useState(false);

  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  
  const onDrop = useCallback((files) => {
    setFilePath(files[0].path || files[0].name);
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet);
      // console.log("EXCEL", data);

      // Check if the required columns are present
      // if (!data.some(item => item.Month === "Month")) {
      //   setErrorMessage("The header doesn't match as Month");
      //   return;
      // }


      const received_data = data.map((item, index) => ({
        id: index + 1,
        month: item.Month,
        year: item.Year,
        sap_code: item.Sap_Code,
        product: item.Product,
        breakup_value: item.Breakup_Value,
        atm_id: item.Atm_Id,
      }));

      // Check for rows with missing data

      // let missingData = false;
      // received_data.forEach(item => {
      //   if (!item.month || !item.year || !item.sap_code || !item.product || !item.breakup_value || !item.atm_id) {
      //     missingData = true;
      //   }
      // });
      // if (missingData) {
      //   setErrorMessage("The file contains rows with missing Month or Year or Sap_Code or Product or Breakup_Value or Atm_Id");
      //   return;
      // }


      setErrorMessage("");
      setRows(received_data);
      setisTrue(true);
      const data1 = received_data;
      console.log("FETCHED DATA", data);
      // dispatch({ type: "excelData", payload: data1 });
    };

    reader.readAsBinaryString(file);
  }, []);

  // Handle ATM ID selection
  const handleAtmIdChange = (event) => {
    setSelectedAtmId(event.target.value);
    setisProfit();
  };

  //Hanle to see Profit
  const handleProfit = () => {
    setisProfit(true);
    setSelectedAtmId();
  }

  // Filter the rows based on the selected ATM ID
  const filteredData = rows.filter((row) => row.atm_id === selectedAtmId);

  // console.log(filteredData);

  // Extract chart data from rows (assumes the rows have relevant chart information)
  // const chartData = rows.reduce(
  //   (acc, row) => {
  //     acc.labels.push(row.product); // Assuming product is the category
  //     acc.values.push(row.breakup_value); // Assuming breakup_value is the bar value
  //     return acc;
  //   },
  //   { labels: [], values: [] }
  // );

  // const chartSetting = {
  //   yAxis: [
  //     {
  //       label: "rainfall (mm)",
  //     },
  //   ],
  //   width: 500,
  //   height: 300,
  //   sx: {
  //     [`.${axisClasses.left} .${axisClasses.label}`]: {
  //       transform: "translate(-20px, 0)",
  //     },
  //   },
  // };

  // const valueFormatter = (value) => `${value}mm`;

  // Get unique ATM IDs for the dropdown
  const uniqueAtmIds = [...new Set(rows.map((row) => row.atm_id))];

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx,.xls",
  });

  const sendData = async (event) => {
    event.preventDefault();

    try {
      // Submitting the form data as JSON
      const response = await axios.post(
        "https://localhost:3001/excel_data",
        rows, // Directly sending the rows array as JSON
        {
          headers: {
            "Content-Type": "application/json", // Change to application/json
          },
        }
      );
      alert("Your Data Has Been Stored Successfully")
      setRows([])
      setisTrue(false);
      setisProfit(false);
      // console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };


  const columns = [
    // { headerName: "Sno", field: "",width:100 },
    { headerName: "Month", field: "month", width: 100 },
    { headerName: "Year", field: "year", width: 100 },
    { headerName: "Sap_Code", field: "sap_code", width: 100 },
    { headerName: "Product", field: "product", width: 150 },
    { headerName: "Breakup_Value", field: "breakup_value", width: 160 },
    { headerName: "Atm_Id", field: "atm_id", width: 100 },
  ];

  // Calculation of the formula for all ATMs
  const calculateFormula = (data1) => {
    if (!rows || rows.length === 0) {
      return null; // Return early if rows is empty or undefined
    }

    // Sum for the addition part of the formula
    const H_K_Sum = rows
      .filter((row) => row.product === "H/K")
      .reduce((acc, row) => acc + row.breakup_value, 0);
    // console.log("H/K", H_K_Sum);
    const LoadingPPMSum = rows
      .filter((row) => row.product === "Loading - PPM")
      .reduce((acc, row) => acc + row.breakup_value, 0);
    // console.log("LoadingPPMSum", LoadingPPMSum);
    const RnMOthersSum = rows
      .filter((row) => row.product === "R n M Others")
      .reduce((acc, row) => acc + row.breakup_value, 0);
    // console.log("RnMOthersSum", RnMOthersSum);
    // Sum for the subtraction part of the formula
    const CBRPenaltySum = rows
      .filter((row) => row.product === "CBR Penalty")
      .reduce((acc, row) => acc + row.breakup_value, 0);
    // console.log("CBRPenaltySum", CBRPenaltySum);
    const RentRecoverySum = rows
      .filter((row) => row.product === "Rent recovery")
      .reduce((acc, row) => acc + row.breakup_value, 0);
    // console.log("RentRecoverySum", RentRecoverySum);
    const LoadingPenaltySum = rows
      .filter((row) => row.product === "Loading penalty")
      .reduce((acc, row) => acc + row.breakup_value, 0);
    // console.log("LoadingPenaltySum", LoadingPenaltySum);
    const InttPenaltySum = rows
      .filter((row) => row.product === "Intt penalty")
      .reduce((acc, row) => acc + row.breakup_value, 0);
    // console.log("InttPenaltySum", InttPenaltySum);
    const MarketingPayout = rows
      .filter((row) => row.product === "Marketing payout")
      .reduce((acc, row) => acc + row.breakup_value, 0);
    // console.log("MarketingPayout", MarketingPayout);
    // Calculate the final value based on the formula
    const total1 = H_K_Sum + LoadingPPMSum + RnMOthersSum + MarketingPayout;
    const total2 =
      CBRPenaltySum + RentRecoverySum + LoadingPenaltySum + InttPenaltySum;
    const result = total1 - total2;
    // console.log("total 1", total1);
    // console.log("total 2", total2);
    // console.log("Result", result);
    return result;
  };

  // Generate the chart data
  const chartData1 = calculateFormula(rows);

  return (
    <Grid style={{ height: "100%" }}>
      <Grid
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "900px",
          margin: "0 auto",
        }}
      >
        <Grid style={{ width: "100%", backgroundColor: "", height: "", display: "flex", justifyContent: "center", border: "2px solid orangered", borderRadius: '1rem' }}>
          <Grid style={{ padding: "1rem", textAlign: 'left', backgroundColor: "yellow", borderRadius: "1rem" }}>
            <Typography style={{ fontWeight: 'bold', textDecoration: "underline" }}>
              Note:-
            </Typography>

            <Typography>
              <ol>
                <li>
                  Excel Reader is used to read the data present in the Excel Sheet.
                </li>
                <li>
                  In Excel Reader we can able to see all the Atm's information based on the month and the year.
                </li>
                <li >
                  While uploading the Excel sheet make sure the headers are as follows <span style={{ color: "red" }}> i.e., Month , Year , Sap_Code , Product , Breakup_Value and Atm_Id.</span>
                </li>
                <li>
                  In the 'Product' make sure the Product Type as follows <span style={{ color: "red" }}>i.e., H/K , Loading - PPM , R n M Others , CBR Penalty , Rent recovery , Loading penalty , Intt penalty and Marketing payout.</span>
                </li>
              </ol>
            </Typography>

          </Grid>
        </Grid>
        <br />
        <Grid
          {...getRootProps()}
          style={{
            border: "2px dashed black",
            padding: "20px",
            width: "100%",
            borderRadius: "5px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <input {...getInputProps()} />
          <Typography>Drag & drop Excel file or</Typography>
          <br />
          <Button variant="contained">Browse</Button>
        </Grid>

        {errorMessage && (
          <p style={{ color: "red", marginBottom: "20px" }}>{errorMessage}</p>
        )}
        {!errorMessage && (
          <Grid>
            {rows && isTrue ? (
              <Grid>
                {filePath && (
                  <p style={{ marginBottom: "20px" }}>
                    Selected File: <strong>{filePath}</strong>
                  </p>
                )}
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.atm_id || row.id}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10, 30, 50,100]}
                />

              </Grid>
            ) : (
              ""
            )}
          </Grid>
        )}
        <Grid>
          {rows.length > 0 && (
            <Grid style={{ display: "flex", justifyContent: "center", gap: "1rem", alignItems: "center", marginTop: "2rem" }}>
              <Grid>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "20px", minWidth: 200 }}
                >
                  <InputLabel>Select ATM ID</InputLabel>
                  <Select
                    value={selectedAtmId}
                    onChange={handleAtmIdChange}
                    label="Select ATM ID"
                  >
                    {uniqueAtmIds.map((atmId) => (
                      <MenuItem key={atmId} value={atmId}>
                        {atmId}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <Grid style={{ marginTop: "1rem" }}>
                  <p>Please select an ATM ID to view the chart.</p>
                </Grid>
              </Grid>
              <Grid>
                <Button variant="contained" onClick={handleProfit} style={{ marginTop: "1.5rem" }}>View Profit</Button>
                <Grid style={{ marginTop: "1rem" }}>
                  <p>By click this you can see the Profit</p>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <Grid style={{ marginTop: "40px" }}>
              {selectedAtmId && filteredData.length > 0 ? (
                <Grid>
                  <BarChart
                    className="barchart1"
                    xAxis={[
                      {
                        scaleType: "band",
                        data: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ], // Fixed months
                        label: "Month",
                      },
                    ]}
                    series={(() => {
                      const groupedData = {};

                      // Group data by month and product
                      filteredData.forEach((row) => {
                        const monthIndex = new Date(`${row.month} 1`).getMonth(); // Convert month name to index
                        if (!groupedData[monthIndex]) {
                          groupedData[monthIndex] = {};
                        }
                        groupedData[monthIndex][row.product] = row.breakup_value;
                      });

                      // Extract series for each product across fixed months
                      const products = [
                        ...new Set(filteredData.map((row) => row.product)),
                      ];

                      return products.map((product) => ({
                        label: product,
                        data: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ].map((month, index) => {
                          return groupedData[index]?.[product] || 0; // Use the month index to map data
                        }),
                      }));
                    })()}
                    width={500}
                    height={450}
                  />

                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          {profit && (
            <Grid style={{ marginTop: "40px" }}>
              <BarChart
                className="barchart2"
                xAxis={[
                  {
                    scaleType: "band",
                    data: ["Profit"], // Only one bar
                    label: "Calculation",
                  },
                ]}
                series={[
                  {
                    label: "Total Profit",
                    data: [chartData1], // Show the result as a single bar
                  },
                ]}
                width={500}
                height={450}
              />
            </Grid>
          )}
          <br />
          {isTrue && (
            <Grid>
              <Button onClick={sendData} style={{ marginTop: "20px" }} variant="contained" >
                Save Data
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExcelReader;
