import React from "react";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Appbar from "./appbar";


function RootLayout() {
    return(
        <div>
            {/* <Header/> */}
            <Appbar/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default RootLayout