import Webcam from "react-webcam";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Grid } from "@mui/material";
import axios from "axios";

const CustomWebcam = ({ onCapture, reset }) => {
  const webcamRef = useRef(null);
  const [isWebcamOpen, setIsWebcamOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  const openWebcam = () => {
    setIsWebcamOpen(true);
  };

  const closeWebcam = () => {
    setIsWebcamOpen(false);
  };
  useEffect(() => {
    if (reset) {
      setImgSrc(null)
      setIsWebcamOpen(false);// Clear the captured image
    }
  }, [reset]);

  // const reverseGeocode = async (latitude, longitude) => {
  //   const apiKey = "YOUR_GOOGLE_MAPS_API_KEY"; // Replace with your actual Google Maps API key
  //   try {
  //     const response = await axios.get(
  //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
  //     );
  //     console.log("Geocode API Response:", response.data);

  //     const results = response.data.results;
  //     return results.length > 0 ? results[0].formatted_address : "Unknown location";
  //   } catch (error) {
  //     console.error("Error in reverse geocoding:", error);
  //     return "Unknown location";
  //   }
  // };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        // `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&zoom=13`
      );
      console.log("Nominatim API Response:", response.data);

      const address = response.data.display_name;
      return address || "Unknown location";
    } catch (error) {
      console.error("Error in reverse geocoding:", error);
      return "Unknown location";
    }
  };
  // 16.065697493579112, 80.55071679878549

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const timestamp = new Date().toISOString();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        console.log(location);
        const locationName = await reverseGeocode(
          location.latitude,
          location.longitude
        );
        onCapture({ imageSrc, timestamp, locationName });
        setImgSrc(imageSrc);
      });
    } else {
      onCapture({
        imageSrc,
        timestamp,
        locationName: "Location not available",
      });
      setImgSrc(imageSrc);
    }
  }, [webcamRef, onCapture]);

  return (
    <div className="container">
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        {!imgSrc && !isWebcamOpen && (
          <Button onClick={openWebcam} variant="contained">
            Upload Photo
          </Button>
        )}

        {isWebcamOpen && !imgSrc && (
          <div>
            <Webcam
              height={100}
              width={100}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
            <div className="btn-container">
              <Button onClick={capture} variant="contained">
                Capture
              </Button>
              <Button onClick={closeWebcam} variant="contained">
                Cancel
              </Button>
            </div>
          </div>
        )}

        {imgSrc && (
          <div>
            <img src={imgSrc} alt="webcam" />
            <div className="btn-container">
              <Button
                onClick={() => {
                  setImgSrc(null);
                  openWebcam();
                }}
                variant="contained"
              >
                Retake
              </Button>
              <Button
                onClick={() => {
                  setImgSrc(null);
                  closeWebcam();
                }}
                variant="contained"
              >
                Delete
              </Button>
              {/* <Button onClick={closeWebcam} variant="contained">
                OK
              </Button> */}
            </div>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default CustomWebcam;
