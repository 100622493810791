import React, { useEffect, useState ,useRef} from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
// import "./App.css";
import html2canvas from "html2canvas";
import { TableFooter } from "@mui/material";

// const initialRows = [
//   {
//     id: 1,
//     // date: new Date().toLocaleDateString(),
//     atmId: "60507905",
//     atmName: "Cherukuru",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 2,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316014",
//     atmName: "Jandrapeta",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 3,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40294305",
//     atmName: "Polytechnic College",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 4,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316010",
//     atmName: "Nehru Market",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 5,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316011",
//     atmName: "MGC",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 6,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316012",
//     atmName: "Darbar Road",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 7,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316013",
//     atmName: "ITC",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 8,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40516101",
//     atmName: "Pandilla Palli",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 9,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40416105",
//     atmName: "Kothapeta",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 10,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40416106",
//     atmName: "Government",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 11,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40416108",
//     atmName: "Vodarevu",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 12,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40594301",
//     atmName: "Vedulapalli",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 13,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40594304",
//     atmName: "Suryalanka Beach",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 14,
//     // date: new Date().toLocaleDateString(),
//     atmId: "60416109",
//     atmName: "Vodarevu 2.0",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 15,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316008",
//     atmName: "HPCL Chirala",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 16,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40407904",
//     atmName: "Parchur",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 17,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40514303",
//     atmName: "Pusapadu Inkollu",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 18,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316104",
//     atmName: "HPCL Vetapalem",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 19,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316009",
//     atmName: "RTC Chirala",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 20,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40599502",
//     atmName: "Swarna",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 21,
//     // date: new Date().toLocaleDateString(),
//     atmId: "60507903",
//     atmName: "Nuthalapadu Parchur",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
//   {
//     id: 22,
//     // date: new Date().toLocaleDateString(),
//     atmId: "40316102",
//     atmName: "vetapalem",
//     cash100: "",
//     cash200: "",
//     cash500: "",
//     total: "",
//   },
// ];

const initialRows =[];

export default function BasicDateCalendar() {
  const [rows, setRows] = useState(initialRows);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState("");


  const [editRowId, setEditRowId] = useState(null);
  
  const [totals, setTotals] = useState({
    total100: 0,
    total200: 0,
    total500: 0,
  });
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const formattedDate = `${String(currentDate.getDate()).padStart(
    2,
    "0"
  )}-${String(currentDate.getMonth() + 1).padStart(
    2,
    "0"
  )}-${currentDate.getFullYear()}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the current date in 'DD-MM-YYYY' format
        const currentDate = new Date();
        const formattedDate = `${String(currentDate.getDate()).padStart(
          2,
          "0"
        )}-${String(currentDate.getMonth() + 1).padStart(
          2,
          "0"
        )}-${currentDate.getFullYear()}`;

        // Fetch data from backend
        const response = await axios.post(
          // `https://192.168.0.108:3003/denomination_Fetch?date=${formattedDate}`
          `https://thulasisolutions.in:5001/denomination_Fetch?date=${formattedDate}`          
        );
        const result = await response.data;

        if (result.success) {
          setData(result.data); // Set data if fetched successfully
        } else {
          console.error("Failed to fetch data");
          setData([]); // Clear data on failure
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setData([]); // Clear data on error
      }
    };

    fetchData();
  }, []);

  const handleDateClick = async (date) => {
    const formattedDate = dayjs(date).format("DD-MM-YYYY"); // Format date to 'YYYY-MM-DD'
    setSelectedDate(formattedDate);

    try {
      // Fetch data from backend
      // const response = await axios.post(
      //   `https://192.168.0.108:3003/denomination_Fetch?date=${formattedDate}`
      // );
      const response = await axios.post(
        `https://thulasisolutions.in:5001/denomination_Fetch?date=${formattedDate}`
      );
      const result = await response.data;

      if (result.success) {
        setData(result.data); // Set data if fetched successfully
      } else {
        console.error("Failed to fetch data");
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setData([]); // Clear data on error
    }
  };
  const calculateTotals = React.useCallback(() => {
    const filteredRows = selectedEmployee
      ? rows.filter((row) => row.employee === selectedEmployee)
      : rows;

    const cash100 = filteredRows.reduce(
      (acc, row) => acc + (row.cash100 || 0),
      0
    );
    const cash200 = filteredRows.reduce(
      (acc, row) => acc + (row.cash200 || 0),
      0
    );
    const cash500 = filteredRows.reduce(
      (acc, row) => acc + (row.cash500 || 0),
      0
    );
    const total100 = filteredRows.reduce(
      (acc, row) => acc + (row.total100 || 0),
      0
    );
    return {
      cash100,
      cash200,
      cash500,
      total: cash100 + cash200 + cash500,
      total100,
    };
  }, [rows, selectedEmployee]);
  const contentRef = useRef();
  const row = calculateTotals();

  // const row = React.useMemo(() => calculateTotals(), [calculateTotals]);
  console.log("Totals:", totals);
  console.log("Rows:", rows);
  console.log("Selected Employee:", selectedEmployee);

  let handleDownloadScreenshot = async () => {
    const elementToCapture = document.getElementById("capture-sections");
    if (elementToCapture) {
      console.log("Element found:", elementToCapture);
      const canvas = await html2canvas(elementToCapture);
      const ctx = canvas.getContext("2d");
  
    // Add the selected date as visible text on the canvas
    ctx.font = "30px Arial";
    ctx.fillStyle = "rgba(59, 77, 235, 1)"; // Blue with full opacity
    ctx.textAlign = "center"; // Center align text
    ctx.fillText(selectedDate || "No Date Selected", canvas.width / 2, canvas.height - 10);
  
    // Convert canvas to image
    const image = canvas.toDataURL("image/png");
  
    // Create file name with the selected date
    const formattedDate = selectedDate
      ? selectedDate.replace(/- /g, "_") 
      : "no_date_selected";
  
    const fileName = `Date_${formattedDate}.png`;
      const link = document.createElement("a");
      link.href = image;
      link.download = fileName;
      link.click();
    } else {
      console.error("Element not found!");
    }
  };


 

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h2>Select a Date</h2>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          onChange={(newDate) => handleDateClick(newDate)}
          //  value={selectedDate ? dayjs(selectedDate) : null}
        />
      </LocalizationProvider>

      <>
        <div  style={{}}>
          <br />
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            ATM DATA
          </Typography>
          <h3>Data for {selectedDate || formattedDate}</h3>
          <Grid
            container
            style={{
              paddingTop: "2rem",
              display: "flex",
              gap: "3rem",
              margin: "1rem",
              justifyContent: "center",
            }}
          >
            <Grid item lg={7}>
              <TableContainer
                // id="capture-sections"
                component={Paper}
                sx={{
                  margin: "dense",
                  size: "small",
                  maxHeight: "590px",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <Table
                  margin="dense"
                  size="small"
                  aria-label="sticky table"
                  stickyHeader
                  id="capture-sections"
                >
                  <TableHead margin="dense" size="small">
                    <TableRow sx={{ backgroundColor: "red" }}>
                      <TableCell style={{ fontWeight: "bold" }}>S.No</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        ATM ID
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        ATM Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        100 Cash
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        200 Cash
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        500 Cash
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Total
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Employee
                      </TableCell>
                    </TableRow>
                    {/* <TableBody> */}
                    {data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.atmId}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.atmName}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.cash100}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.cash200}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.cash500}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.total}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.employee}
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* </TableBody> */}
                  </TableHead>

                  <TableFooter>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        style={{ fontWeight: "bold", fontSize: "18px" }}
                      >
                        <strong>Totals:</strong>
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {data.reduce((sum, row) => sum + (row.cash100 || 0), 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {data.reduce((sum, row) => sum + (row.cash200 || 0), 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {data.reduce((sum, row) => sum + (row.cash500 || 0), 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {data.reduce((sum, row) => sum + (row.total || 0), 0)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Button
            download
            variant="contained"
            color="primary"
            onClick={handleDownloadScreenshot}
            style={{ marginLeft: "1rem", marginTop: "1rem" }}
          >
            Download
          </Button>
        </div>
        <br />
      </>
    </div>
  );
}
