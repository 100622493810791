import { createStore, combineReducers, applyMiddleware } from 'redux';
import { logger } from 'redux-logger';
import { thunk } from 'redux-thunk';  // Change this line
import Get_excel_Data from './reducers/reducer1';

const rootReducer = combineReducers({
  excelData: Get_excel_Data,
//   agencyList: FetchAgencyData,
});

const store = createStore(rootReducer, applyMiddleware(thunk, logger)); // You can include logger if needed
console.log("store",store);
export default store;
