import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import Sidebar from "./sidebar";
import axios from "axios";

const UserTable = () => {
  const initialRows = [
    // Sample data for demonstration
    {
      id: 1,
      date: new Date().toLocaleDateString(),
      atmId: "40416106",
      atmName: "Government",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 2,
      date: new Date().toLocaleDateString(),
      atmId: "40416105",
      atmName: "Kothapeta",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 3,
      date: new Date().toLocaleDateString(),
      atmId: "40316011",
      atmName: "MGC",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 4,
      date: new Date().toLocaleDateString(),
      atmId: "40316010",
      atmName: "Nehru Market",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 5,
      date: new Date().toLocaleDateString(),
      atmId: "40316012",
      atmName: "Darbar Road",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 6,
      date: new Date().toLocaleDateString(),
      atmId: "40416108",
      atmName: "Vodrev Road",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 7,
      date: new Date().toLocaleDateString(),
      atmId: "40316013",
      atmName: "ITC",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 8,
      date: new Date().toLocaleDateString(),
      atmId: "40294305",
      atmName: "Poltechnique col",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 9,
      date: new Date().toLocaleDateString(),
      atmId: "40594304",
      atmName: "Suryalanka beach",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
    {
      id: 10,
      date: new Date().toLocaleDateString(),
      atmId: "60416109",
      atmName: "Vodrev 2.0",
      cash100: "",
      cash200: "",
      cash500: "",
      rcm: "",
      remark: "",
    },
  ];

  const [rows, setRows] = useState(initialRows); // initialRows should be defined with your data
  const [editRowId, setEditRowId] = useState(null);

  const handleEditClick = (id) => {
    setEditRowId(id);
  };

  const handleInputChange = (event, id, fieldName) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [fieldName]: event.target.value };

        // Assuming 'Existing' and 'Enter' fields have corresponding numeric values in the row
        if (fieldName.endsWith("Enter")) {
          // Calculate 'Total' for the corresponding cash balance when 'Enter' changes
          const balanceType = fieldName.replace("Enter", ""); // e.g., 'cash100' if fieldName is 'cash100Enter'
          const existingValue = parseFloat(event.target.value) || 0; // Parse the 'Existing' value or default to 0
          const enterValue = parseFloat(event.target.value) || 0; // Parse the 'Enter' value or default to 0
          const totalValue = existingValue + enterValue;
          updatedRow[balanceType + "Total"] = totalValue.toString(); // Update the 'Total' field
        }

        return updatedRow;
      }
      return row;
    });
    setRows(newRows);
  };
  const handleSaveClick = (id) => {
    setEditRowId(null); // Exit edit mode for the row
  };

  const handleSubmit = async (event) => {
    // console.log(rows)
    event.preventDefault(); // Prevent form submission refresh
    try {
      // Submitting the form data
      const response = await axios.post(
        "https://localhost:3001/indent_slip",
        rows,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  return (
    <div align="center" style={{ paddingBottom: "2rem" }}>
      <Typography
        style={{
          fontWeight: "bold",
          marginTop: "1rem",
          // textDecoration: "underline",
        }}
        variant="h5"
      >
        INDENT SLIP FORM
      </Typography>
      <Grid
        style={{
          width: "90%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ width: "90%", marginTop: "20px" }}
        >
          <Table className="editable-table">
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>
                  <b>Date</b>
                </TableCell>
                <TableCell rowSpan={2}>
                  <b>ATM ID</b>
                </TableCell>
                <TableCell rowSpan={2}>
                  <b>ATM NAME</b>
                </TableCell>
                <TableCell colSpan={3}>
                  <b>100 Cash Balance</b>
                </TableCell>{" "}
                {/* Updated to span 3 columns */}
                <TableCell colSpan={3}>
                  <b>200 Cash Balance</b>
                </TableCell>{" "}
                {/* Updated to span 3 columns */}
                <TableCell colSpan={3}>
                  <b>500 Cash Balance</b>
                </TableCell>{" "}
                {/* Updated to span 3 columns */}
                {/* <TableCell rowSpan={2}>Total</TableCell> */}
                <TableCell rowSpan={2}>
                  <b>RCM</b>
                </TableCell>
                <TableCell rowSpan={2}>
                  <b>Remarks</b>
                </TableCell>
                <TableCell rowSpan={2}>
                  <b>Action</b>
                </TableCell>
              </TableRow>
              <TableRow>
                {/* Sub-columns for each cash balance */}
                <TableCell>Existing</TableCell>
                <TableCell>Enter</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Existing</TableCell>
                <TableCell>Enter</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Existing</TableCell>
                <TableCell>Enter</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  {/* Display the date, ATM ID, and ATM NAME */}
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.atmId}</TableCell>
                  <TableCell>{row.atmName}</TableCell>

                  {/* Editable cash balance fields */}
                  {["cash100", "cash200", "cash500"].map((balanceType) => {
                    const existingValue = balanceType + "Existing";
                    const enterValue = balanceType + "Enter";
                    const totalValue = balanceType + "Total";
                    return (
                      <>
                        <TableCell>{row[existingValue]}</TableCell>
                        {editRowId === row.id ? (
                          <TableCell>
                            <TextField
                              size="small"
                              value={row[enterValue]}
                              onChange={(event) =>
                                handleInputChange(event, row.id, enterValue)
                              }
                            />
                          </TableCell>
                        ) : (
                          <TableCell>{row[enterValue]}</TableCell>
                        )}
                        <TableCell>{row[totalValue]}</TableCell>
                      </>
                    );
                  })}

                  {/* RCM and Remarks fields */}
                  {/* <TableCell>{row.rcm}</TableCell>
          <TableCell>{row.remark}</TableCell> */}
                  {editRowId === row.id ? (
                    <>
                      <TableCell>
                        <TextField
                          size="small"
                          value={row.rcm}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "rcm")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          value={row.remark}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "remark")
                          }
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{row.rcm}</TableCell>
                      <TableCell>{row.remark}</TableCell>
                    </>
                  )}

                  {/* Action buttons */}
                  <TableCell>
                    {editRowId === row.id ? (
                      <Button
                        variant="contained"
                        onClick={() => handleSaveClick(row.id)}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => handleEditClick(row.id)}
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>{" "}
          </Table>
        </TableContainer>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </div>
  );
};

export default UserTable;
