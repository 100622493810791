import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import {
  Grid,
  // Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Box,
  Button,
} from "@mui/material";
import axios from "axios";
import Modal from "@mui/material/Modal";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import html2canvas from "html2canvas";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// 0000
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

// import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

// import SpeedDialIcon from "@mui/material/SpeedDialIcon";
// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import PrintIcon from "@mui/icons-material/Print";
// import ShareIcon from '@mui/icons-material/Share';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "90vw",
  maxHeight: "90vh",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  // p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
};

export default function VIEWDATA() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [atmData, setAtmData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalImage, setModalImage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [direction, setDirection] = React.useState("");

  const actions = [
    { icon: <EmailIcon />, name: "Mail" },
    { icon: <WhatsAppIcon />, name: "whats App" },
    // { icon: <SaveIcon />, name: 'Save' },
    // { icon: <FileCopyIcon />, name: 'Copy' },
    // { icon: <ShareIcon />, name: 'Share' },
  ];
  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const handleClose = () => setOpen(false);
  const handleModal = (image) => {
    setModalImage(image);
    setOpen(true);
  };
  // Filter ATM data based on the selected date
  const filterDataByDate = (date, data) => {
    return data.filter((atm) => dayjs(atm.date).isSame(date, "day"));
  };

  // Handle Date Change
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    const filtered = filterDataByDate(newDate, atmData);
    setFilteredData(filtered);
  };
  // const handleDownloadScreenshot = async () => {
  //   const elementToCapture = document.getElementById("capture-modal-img");
  //   if (elementToCapture) {
  //     console.log("Element found:", typeof(elementToCapture));
  //     const canvas = await html2canvas(elementToCapture);
  //     const image = canvas.toDataURL("image/png");
  //     const link = document.createElement("a");
  //     link.href = image;
  //     link.download = "screenshot.png";
  //     link.click();
  //   } else {
  //     console.error("Element not found!");
  //   }
  // };

  // const captureScreenshot = () => {
  //   const element = document.getElementById('your-element-id'); // Replace with the ID of the element you want to capture
  //   html2canvas(element).then((canvas) => {
  //     const image = canvas.toDataURL('image/png');
  //     // You can now download the image or use it elsewhere
  //     console.log(image);
  //   });
  // };

  

  useEffect(() => {
    // Fetch Data from API
    const fetchData = async () => {
      try {
        // const response = await axios.get("https://192.168.0.108:3003/atm_info");
        const response = await axios.get("https:// thulasisolutions.in:5001/atm_info");

        let data = [];

        if (Array.isArray(response.data)) {
          data = response.data;
        } else if (response.data && Array.isArray(response.data.data)) {
          data = response.data.data;
        } else {
          console.error("Unexpected response format:", response.data);
        }

        setAtmData(data);

        // Filter data for the current date
        const filtered = filterDataByDate(dayjs(), data);
        setFilteredData(filtered);
      } catch (error) {
        console.error("Error fetching ATM data:", error);
      }
    };
    fetchData();
  }, []);

  // console.log("Filtered Data:", filteredData);

  return (
    <div>
      {/* Date Picker */}
      <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar value={selectedDate} onChange={handleDateChange} />
        </LocalizationProvider>
      </Grid>

      {/* Table */}
      <TableContainer
        sx={{
          margin: "dense",
          size: "small",
          maxHeight: "590px",
          overflow: "auto",
          width: "100%",
        }}
      >
        <Table
          margin="dense"
          size="small"
          aria-label="sticky table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>S.No</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>ATM ID</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>ATM Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Slips</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Cleaning</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredData.map((atm, index) => (
              <TableRow key={atm.id || index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{atm.ATM_ID}</TableCell>
                <TableCell>{atm.ATM_NAME}</TableCell>
                <TableCell>
                  <img
                    onClick={() => {
                      handleModal(atm.image1);
                    }}
                    // src={`https://localhost:3003/img/${atm.image1 || ""}`}
                    src={`https://thulasisolutions.in:5001/img/${atm.image1 || ""}`}
                    alt="ATM Slip"
                    height="30%"
                    width="30%"
                  />
                </TableCell>
                <TableCell>
                  <img
                    onClick={() => {
                      handleModal(atm.image2);
                    }}
                    // src={`https://localhost:3003/img/${atm.image2 || ""}`}
                    src={`https://thulasisolutions.in:5001/img/${atm.image2 || ""}`}
                    alt="ATM Cleaning"
                    height="30%"
                    width="30%"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
      >
        <a
          // href="https://localhost:3000/AtmServiceData"
          href="https://thulasisolutions.in:5001/AtmServiceData"
          style={{ textDecorationLine: "none", color: "white" }}
        >
          Click here to Uploads
        </a>
      </Button>
      <div>
        <Modal
          //  id="capture-section"
          open={open}
          onClose={handleClose}
          aria-labelledby="Slip-atm"
          aria-describedby="Atm-slip"
        >
          <Box style={{ ...style, textAlign: "center" }}>
            {/* ****** */}
            <MediaCard
              image={modalImage}
              // type={"abc"}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export function MediaCard(props) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleDownloadScreenshot = async () => {
    const elementToCapture = document.getElementById("capture-modal-img");
    if (elementToCapture) {
      try {
        const canvas = await html2canvas(elementToCapture, {
          useCORS: true,
          backgroundColor: "#fff",
        });
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = "screenshot.png";
        link.click();
      } catch (error) {
        console.error("Failed to capture the screenshot:", error);
        alert("Something went wrong while capturing the screenshot. Please try again.");
      }
    } else {
      alert("Element not found!");
    }
  };
  return (
    <Card sx={{}}>
      <CardMedia
        id="capture-modal-img"
        sx={{ height: "60vh", width: "60vw" }}
        // image={`https://localhost:3003/img/${props.image}`}
        image={`https:// thulasisolutions.in:5001/img/${props.image}`}
        title="green iguana"
      />
      <CardActions sx={{ textAlign: "center" }}>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <Button size="small" onClick={handleDownloadScreenshot}>
          {" "}
          <DownloadIcon />
        </Button>
      </CardActions>
    </Card>
  );
}
