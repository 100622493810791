import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { BarChart } from "@mui/x-charts/BarChart";

import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";

const Excel_viewer = () => {
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [rows1, setrows1] = useState([]);
  const [selectedAtmId, setSelectedAtmId] = useState("");
  const [isTrue, setisTrue] = useState(false);

  // Fetch previous data from data base
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://localhost:3001/excel_data", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        // console.log("Data fetched successfully:", response.data);
        const ResponseData = response.data;
        const excelsheet = ResponseData.map((item, index) => ({
          id: index + 1,
          month: item.month,
          year: item.year,
          sap_code: item.sap_sode,
          product: item.product,
          breakup_value: item.breakup_value,
          atm_id: item.atm_id,
        }));

        setErrorMessage("");
        setrows1(excelsheet);
        // setData(response.data);
        console.log("exceldata", excelsheet); // Save the fetched data to state
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        setError(error); // Save the error to state
      }
    };

    fetchData(); // Call the fetchData function
  }, []); //

  //filter the data according to the id.
  const filteredData = rows1.filter((row) => row.atm_id === selectedAtmId);

  // Handle ATM ID selection
  const handleAtmIdChange = (event) => {
    setSelectedAtmId(event.target.value);
  };

  //unique id
  const uniqueAtmIds = [...new Set(rows1.map((row) => row.atm_id))];

  return (
    <div style={{ height: "", display: "flex", justifyContent: "center",flexDirection:"column",alignItems:"center" }}>
      <Grid
        style={{
            width: "30%",
          marginTop: "2rem",
          marginBottom: "2rem",
          backgroundColor: "",
          display: "flex",
          justifyContent: "center",
          border: "2px solid orangered",
          borderRadius: "1rem",
        }}
      >
        <Grid
          style={{
            width: "100%",
            padding: "1rem",
            textAlign: "left",
            backgroundColor: "yellow",
            borderRadius: "1rem",
          }}
        >
          <Typography
            style={{ fontWeight: "bold", textDecoration: "underline" }}
          >
            Note:-
          </Typography>

          <Typography>
            <ol>
              <li>
                Excel Viewer Page is used to see the previous data stored in the
                database.
              </li>
              <li>
                Here there is a input field which consists of all the ATM_ID's
                in it.
              </li>
              <li>
                Select an Atm Id to see the data related to that particular ATM.
              </li>
              {/* <li>
                In the 'Product' make sure the Product Type as follows{" "}
                <span style={{ color: "red" }}>
                  i.e., H/K , Loading - PPM , R n M Others , CBR Penalty , Rent
                  recovery , Loading penalty , Intt penalty and Marketing
                  payout.
                </span>
              </li> */}
            </ol>
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Grid>
          {rows1.length > 0 && (
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <Grid>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "20px", minWidth: 200 }}
                >
                  <InputLabel>Select ATM ID</InputLabel>
                  <Select
                    value={selectedAtmId}
                    onChange={handleAtmIdChange}
                    label="Select ATM ID"
                  >
                    {uniqueAtmIds.map((atmId) => (
                      <MenuItem key={atmId} value={atmId}>
                        {atmId}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <Grid style={{ marginTop: "1rem" }}>
                  <p>Please select an ATM ID to view the chart.</p>
                </Grid>
              </Grid>
              {/* <Grid>
                <Button
                  variant="contained"
                  onClick={handleProfit}
                  style={{ marginTop: "1.5rem" }}
                >
                  View Profit
                </Button>
                <Grid style={{ marginTop: "1rem" }}>
                  <p>By click this you can see the Profit</p>
                </Grid>
              </Grid> */}
            </Grid>
          )}
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <Grid style={{ marginTop: "40px" }}>
              {selectedAtmId && filteredData.length > 0 ? (
                <Grid>
                  <BarChart
                    className="barchart1"
                    xAxis={[
                      {
                        scaleType: "band",
                        data: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ], // Fixed months
                        label: "Month",
                      },
                    ]}
                    series={(() => {
                      const groupedData = {};

                      // Group data by month and product
                      filteredData.forEach((row) => {
                        const monthIndex = new Date(
                          `${row.month} 1`
                        ).getMonth(); // Convert month name to index
                        if (!groupedData[monthIndex]) {
                          groupedData[monthIndex] = {};
                        }
                        groupedData[monthIndex][row.product] =
                          row.breakup_value;
                      });

                      // Extract series for each product across fixed months
                      const products = [
                        ...new Set(filteredData.map((row) => row.product)),
                      ];

                      return products.map((product) => ({
                        label: product,
                        data: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ].map((month, index) => {
                          return groupedData[index]?.[product] || 0; // Use the month index to map data
                        }),
                      }));
                    })()}
                    width={500}
                    height={450}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          {/* {profit && (
            <Grid style={{ marginTop: "40px" }}>
              <BarChart
                className="barchart2"
                xAxis={[
                  {
                    scaleType: "band",
                    data: ["Profit"], // Only one bar
                    label: "Calculation",
                  },
                ]}
                series={[
                  {
                    label: "Total Profit",
                    data: [chartData1], // Show the result as a single bar
                  },
                ]}
                width={500}
                height={450}
              />
            </Grid>
          )} */}
          <br />
          {/* {isTrue && (
            <Grid>
              <Button
                onClick={sendData}
                style={{ marginTop: "20px" }}
                variant="contained"
              >
                Save Data
              </Button>
            </Grid>
          )} */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Excel_viewer;
