import React, { useState } from "react";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
// import axios from "axios";
import RadioGroup from "@mui/material/RadioGroup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link } from "react-router-dom";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useCheckbox from "./CheckedContext";
// import dayjs from "dayjs";

function Dataform() {
  const InitialFormData = {};
  const [formData, setFormData] = useState(InitialFormData);
  const handleInputChange = (event) => {
    let payload = { ...formData, [event.target.name]: event.target.value };
    setFormData(payload);
  };

  // const handleDateChange = (newDate) => {
  //   // Format the date to exclude the time part
  //   const formattedDate = newDate ? dayjs(newDate).format("YYYY-MM-DD") : "";
  //   setFormData({ ...formData, date: formattedDate });
  // };
  // Handle file upload hook
  const handleVideoUpload = (event) => {
    const normalvideo = event.target.files[0];
    console.log(normalvideo);
    setFormData({ ...formData, normalvideo: normalvideo });
  };
  // Handle document upload function
  const handleDocumentUpload = (event) => {
    const docfile = event.target.files[0];
    console.log(docfile);
    setFormData({ ...formData, docfile: docfile });
  };
  // const handleDocumentUpload = (event) => {
  //   // Convert FileList to an array
  //   const filesArray = Array.from(event.target.files);
  //   console.log(filesArray);

  //   // Update your state to include the array of files
  //   setFormData({ ...formData, docfile: filesArray });
  // };

  const handleImageUpload = (event) => {
    const imagefile = event.target.files[0];
    console.log(imagefile);
    setFormData({ ...formData, imagefile: imagefile });
  };

  const handleErrorVideoUpload = (event) => {
    const errorvideo = event.target.files[0];
    console.log(errorvideo);
    setFormData({ ...formData, errorvideo: errorvideo });
  };

  const handleErrorDocUpload = (event) => {
    const errordoc = event.target.files[0];
    console.log(errordoc);
    setFormData({ ...formData, errordoc: errordoc });
  };
  const handlerrorImageUpload = (event) => {
    const errorimagefile = event.target.files[0];
    console.log(errorimagefile);
    setFormData({ ...formData, errorimagefile: errorimagefile });
  };

  //fetching the data and storing it in the state 'data'

  const [data, setData] = useState([]);
  const handleOnSubmit = (event) => {
    event.preventDefault();
    // post data code --axios
    console.log("FORM DATA", formData);
    setData([...data, formData]);

    // try {
    //   const response = axios.post(
    //     "http://192.168.1.111:9000/createPost",
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // const [formData, setFormData] = useState(InitialFormData);
  const [isChecked1, , setIsChecked1] = useCheckbox(); // Destructure setIsChecked1 from useCheckbox
  const handleRadio = (event) => {
    const selectedValue = event.target.value;
    setFormData({ ...formData, radioButtonOption: selectedValue });
    setIsChecked1(selectedValue === "PENDING"); // Update isChecked1 based on radio selection
  };

  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className="App">
      {/* <Appbar /> */}
      <Grid
        container
        style={{
          // backgroundColor: "#F8F9FA",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            marginTop: "2rem",
            textDecoration: "underline",
          }}
          variant="h5"
        >
          ATM SERVICE FORM
        </Typography>
        <form
          // onSubmit={handleOnSubmit}
          style={{
            padding: "30px",
            width: "30rem",
            // backgroundColor: "#E6FFFD",
            paddingTop: "20px",
            paddingBottom: "20px",
            marginTop: "20px",
            marginBottom: "10px",
            border: "1px solid black",
            borderRadius: "1rem",
          }}
        >
          <Grid
            style={{
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              gap: "0px",
            }}
          >
            <Grid style={{ padding: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Employee</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Employee"
                  value={formData.name}
                  name="Employee"
                  onChange={handleInputChange}
                  style={{ width: "" }}
                >
                  <MenuItem value="Employee 1">Employee 1</MenuItem>
                  <MenuItem value="Employee 2">Employee 2</MenuItem>
                  <MenuItem value="Employee 3"> Employee 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid style={{ padding: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                <Select
                  label="Region"
                  value={formData.course}
                  name=""
                  onChange={handleInputChange}
                  style={{ width: "" }}
                >
                  <MenuItem value="ONGOLE">ONGOLE</MenuItem>
                  <MenuItem value="MARKAPUR">MARKAPUR</MenuItem>
                  <MenuItem value="CHIRALA">CHIRALA</MenuItem>
                  <MenuItem value="THULASI SOLUTIONS">
                    THULASI SOLUTIONS
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{width:"100%",backgroundColor:""}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker label="Date"  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid style={{ padding: "10px" }}>
            <TextField
              fullWidth
              id="outlined-multiline-static fullWidth"
              label="description"
              name="description"
              multiline
              rows={4}
              value={formData.description}
              onChange={handleInputChange}
              // defaultValue="Enter Text"
            />
          </Grid>
          {/* File upload buttons grid */}
          <Grid
            style={{
              display: "flex",
              gap: "30px",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
              gap: "50px",
            }}
          >
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload Photo
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>

          {/* submit Button */}
          <Grid style={{ padding: "10px" }}>
            <Button fullWidth variant="contained" color="success" type="submit">
              Submit
            </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}

export default Dataform;
