import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LaunchIcon  from "@mui/icons-material/Input";
import PendingIcon from "@mui/icons-material/Pending";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LaunchIcon from "@mui/icons-material/Launch";
import CancelIcon from "@mui/icons-material/Cancel";
// import { Label } from "@mui/icons-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import RemoveIcon from "@mui/icons-material/Remove";
import useCheckbox from "./CheckedContext";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

// const Bankstatement = ({ onDelete }) => {
const Bankstatement = () => {
  const InitialFormdata = {
    date: "",
    description: "",
    status: "",
    docfile: [],
  };

  const [formData, setFormData] = React.useState(InitialFormdata);
  const [savedData, setSavedData] = React.useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // Removed the handleClose function from Modal's onClose to disable outside click to close
  const handleCloseButton = () => {
    setOpen(false); // Use this function for the close button inside the modal
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // State to store the list of uploaded files
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleInputChange = (event) => {
    let payload = { ...formData, [event.target.name]: event.target.value };
    setFormData(payload);
  };

  const handleDateChange = (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format("YYYY-MM-DD") : "";
    setFormData({ ...formData, date: formattedDate });
  };
  // Handle file upload
  const handleDocumentUpload = (event) => {
    const files = Array.from(event.target.files);
    setFormData({ ...formData, docfile: [...formData.docfile, ...files] });
    event.target.value = "";
  };

  // Function to remove file from list
  const removeFile = (fileType, index) => {
    setFormData((prevState) => ({
      ...prevState,
      [fileType]: prevState[fileType].filter((_, i) => i !== index),
    }));
  };

  // const [isChecked1, , setIsChecked1] = useCheckbox(); // Destructure setIsChecked1 from useCheckbox
  const handleRadio = (event) => {
    const selectedValue = event.target.value;
    setFormData({ ...formData, status: selectedValue });
  };
  // setIsChecked1(selectedValue === "PENDING"); // Update isChecked1 based on radio selection

  useEffect(() => {
    console.log(savedData); // This will log whenever savedData changes.
  }, [savedData]);

  const handleOnSubmit = (event) => {
    event.preventDefault();

    setSavedData(formData);

    // setData((prevData) => [...prevData, formData]);

    alert("YOUR DATA HAS BEEN SUBMITTED");
    // Reset the form data to its initial state to clear the form

    setFormData(InitialFormdata);
    // console.log(formData);
    // console.log(savedData);
  };

  const handleonupdate = () => {};
  function createData(
    month: string,
    status: number,
    totalfiles: number,
    view: number
    // protein: number
  ) {
    return { month, status, totalfiles, view };
  }
  const rows = [
    createData(
      "Jan",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "Feb",

      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen} style={{ width: "10px" }}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "Mar",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "Apr",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "May",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "June",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "July",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "Aug",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "Sep",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "Oct",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "Nov",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
    createData(
      "Dec",
      <Typography>
        {formData.status === "PENDING" ? (
          <Tooltip title="Pending" arrow>
            <PendingIcon style={{ color: "red" }} alt="Pending" />
          </Tooltip>
        ) : formData.status === "SUCCESS" ? (
          <Tooltip title="Uploaded" arrow>
            <CheckCircleIcon style={{ color: "green" }} alt="uploaded" />
          </Tooltip>
        ) : (
          <Tooltip title=" Not uploaded" arrow>
            <RemoveIcon style={{ color: "black" }} alt=" not uploaded" />
          </Tooltip>
        )}
      </Typography>,
      <Typography>{formData.docfile.length}</Typography>,
      <Button onClick={handleOpen}>
        <LaunchIcon />
      </Button>
    ),
  ];
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleOnSubmit}>
            <Grid>
              <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={handleCloseButton}
                  style={{ marginTop: "10px" }}
                >
                  <CancelIcon
                    // onClick={() => removeFile(index)}
                    style={{
                      cursor: "pointer",
                      color: "brown",
                      // height: "1.3rem",
                    }}
                  />
                </Button>
              </Grid>
              <Grid style={{ display: "flex", gap: "50px" }}>
                <Grid
                  style={{
                    padding: "10px",
                    display: "flex",
                    width: "220px",
                    flexDirection: "column",
                    fontWeight: "bold",
                    backgroundColor: "#92C7CF",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    style={{
                      width: "100%",
                      color: "black",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      paddingBottom: "5px",
                      backgroundColor: "#92C7CF",
                    }}
                  >
                    {/* <CloudUploadIcon />  */}
                    Upload Doc
                  </Typography>
                  <Button
                    component="label"
                    variant="contained"
                    style={{ backgroundColor: "lightgrey", color: "black" }}
                  >
                    <input
                      type="file"
                      name="docfile"
                      multiple
                      style={{ cursor: "pointer", display: "none" }}
                      onChange={handleDocumentUpload}
                      // value={formData.docfile}
                    />
                    Choose Files
                  </Button>{" "}
                  {formData.docfile?.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontSize: "0.9rem",
                      }}
                    >
                      {file.name}
                      {/* Uncomment the next line if you are using the CancelIcon */}
                      <CancelIcon
                        onClick={() => removeFile("docfile", index)}
                        style={{
                          cursor: "pointer",
                          color: "brown",
                          height: "1.3rem",
                        }}
                      />
                    </div>
                  ))}
                </Grid>
                <Grid style={{ width: "70%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        onChange={handleDateChange}
                        name="date"
                        value={formData.date}
                        label="Choose Date"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText="Please fill this field"
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <br />
                  <Grid>
                    <TextField
                      label="Description"
                      fullWidth
                      multiline
                      name="description"
                      type="text"
                      rows={5}
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <Grid>
                <input
                  type="radio"
                  value="SUCCESS"
                  checked={formData.status === "SUCCESS"}
                  onChange={handleRadio}
                  // onClick={handleCheckboxChange1===false}
                />{" "}
                SUCCESS
                <input
                  type="radio"
                  value="PENDING"
                  checked={formData.status === "PENDING"}
                  onChange={handleRadio}
                  // onClick={handleCheckboxChange1}
                />{" "}
                PENDING
              </Grid>
              <br />
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  gap: "30px",
                }}
              >
                <Button variant="contained" type="submit">
                  Submit
                </Button>
                <Button
                  variant="contained"
                  onClick={handleonupdate}
                  color="success"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
          <hr />
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              List{" "}

              {Array.isArray(savedData.docfile) &&
                  savedData.docfile.length > 0 &&
                  savedData.docfile.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontSize: "0.9rem",
                      }}
                    >
                      <ol>
                      <li style={{ textDecoration: "none" }}>
                        <Link>{file.name}</Link>
                      </li>{" "}
                      </ol>
                      {/* Uncomment the next line if you are using the CancelIcon */}
                      <CancelIcon
                        onClick={() => removeFile("docfile", index)}
                        style={{
                          cursor: "pointer",
                          color: "brown",
                          height: "1.3rem",
                        }}
                      />
                    </div>
                  ))}
              {/* <div style={{ textDecoration: "none", fontWeight: "normal" }}>
                {" "}
                {Array.isArray(savedData.docfile) &&
                  savedData.docfile.length > 0 &&
                  savedData.docfile.map((file, index) => (
                    <ol
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // marginTop: "10px",
                        fontSize: "0.9rem",
                      }}
                    >
                      <li style={{ textDecoration: "none" }}>
                        <Link>{file.name}</Link>
                      </li>{" "}
                    </ol>
                    
                  ))}
              </div> */}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              Status:{savedData.status}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              Date:{savedData.date}
            </Typography>
          </Grid>
          <Typography style={{ border: "1px solid black", height: "100px" }}>
            {savedData.description}
          </Typography>
        </Box>
      </Modal>
      {/* <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Grid> */}
      <TableContainer component={Paper} style={{ height: "470px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ backgroundColor: "#BF3131" }}>
                Month
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                Files
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                View
              </StyledTableCell>
              {/* <StyledTableCell  align="center">Pro</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.month} style={{ height: "10px" }}>
                <StyledTableCell component="th" scope="row">
                  {row.month}
                </StyledTableCell>
                <StyledTableCell align="center">{row.status}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.totalfiles}
                </StyledTableCell>
                <StyledTableCell align="center">{row.view}</StyledTableCell>
                {/* <StyledTableCell  align="center">
                      {row.protein}
                    </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Bankstatement;
