import React, { useState } from "react";
import image from "../../assets/one.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("");
  };

  return (
    <div
      // onClick={handleClick}
      style={{
        height: "80vh",
        backgroundImage: `url(${image})`,
        backgroundSize: "80% 80vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        cursor: "pointer",
      }}
    ></div>
  );
};

export default Home;
