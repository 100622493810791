import React from "react";
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./components/pages/rootlayout";
import Home from "./components/pages/home";
import NewEntry from "./components/pages/Indent_upload_form";
import UserTable from "./components/pages/Indent_slip_form";
import Documentform from "./components/pages/monthlydocs";
import Bankstatement from "./components/pages/bankStatements";
import Sidebar from "./components/pages/sidebar";
import Documentform1 from "./components/pages/yearlydocs";
import Viewtable from "./components/pages/viewcontent";
import Viewtable1 from "./components/pages/viewcontent1";
import DynamicEditableTable from "./components/pages/dummy";
import DynamicEditableTable1 from "./components/pages/dummy1";
import ATMSERVICE from "./components/pages/AtmServiceData";
import { Provider } from "react-redux";
import store from "./components/store/store";
import Dataform from "./components/pages/dataform";
import CustomWebcam from "./components/pages/webcam"
import ExcelReader from "./components/pages/excelReader";
import BasicPie from "./components/charts/piechart";
import Excel_viewer from "./components/pages/excelViewer";
import AddDataEntry from "./components/pages/AddData";
import AddnewColumn from "./components/pages/Addcolumn";
import EntryData from "./components/pages/Formuploaddata";
import AtmDataDisplay from "./components/pages/ATMDataView";
import BasicDateCalendar from "./components/pages/DateCalendar";
import VIEWDATA from "./components/pages/viewslipform";
// import VIEWDATA from "./components/pages/viewslipform";
// import Viewtable1 from "./components/Dashboardcomponents/viewcontent";

const common = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/Indent_upload_form", element: <NewEntry /> },
      { path: "/Indent_slip_form", element: <UserTable /> },
      { path: "/monthlydocs", element: <Documentform /> },
      { path: "/bankStatement", element: <Bankstatement /> },
      { path: "/sidebar", element: <Sidebar /> },
      { path: "/yearlydocs", element: <Documentform1 /> },
      { path: "/viewcontent", element: <Viewtable /> },
      { path: "/viewcontent1", element: <Viewtable1 /> },
      { path: "/dummy", element: <DynamicEditableTable /> },
      { path: "/dummy1", element: <DynamicEditableTable1 /> },
      { path: "/AtmServiceData", element: <ATMSERVICE /> },
      { path: "/dataform", element: <Dataform /> },
      { path: "/webcam", element: <CustomWebcam /> },
      { path:"/excelReader",element:<ExcelReader/>},
      { path:"/piechart",element:<BasicPie/>},
      { path:"/excelViewer",element:<Excel_viewer/>},
      { path:"/AddData",element:<AddDataEntry/>},
      { path:"/Addcolumn",element:<AddnewColumn/>},
      { path:"/formuploaddata",element:<EntryData/>},
      { path:"/ATMDataView",element:<AtmDataDisplay/>},
      { path:"/DateCalendar",element:<BasicDateCalendar/>},
      { path:"/viewslipform",element:<VIEWDATA/>},
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <RouterProvider router={common} />
      </Provider>
    </div>
  );
}
export default App;
