import React, { useState } from 'react';
// Import the CSS directly if using CSS Modules or similar setup
import '../css/DynamicEditableTable.css';
const initialContacts = [
  { id: 1, fullName: 'Name 1', email: '', phone: '', address: '', city: '' },
  { id: 2, fullName: 'Name 2', email: '', phone: '', address: '', city: '' },
  { id: 3, fullName: 'Name 3', email: '', phone: '', address: '', city: '' },
  { id: 4, fullName: 'Name 4', email: '', phone: '', address: '', city: '' },
  { id: 5, fullName: 'Name 5', email: '', phone: '', address: '', city: '' },
  { id: 6, fullName: 'Name 6', email: '', phone: '', address: '', city: '' },
  { id: 7, fullName: 'Name 7', email: '', phone: '', address: '', city: '' },
  { id: 8, fullName: 'Name 8', email: '', phone: '', address: '', city: '' },
  { id: 9, fullName: 'Name 9', email: '', phone: '', address: '', city: '' },
  { id: 10, fullName: 'Name 10', email: '', phone: '', address: '', city: '' },
];


const DynamicEditableTable = () => {
  const [contacts, setContacts] = useState(initialContacts);
  const [addFormData, setAddFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    city: ''
  });
  const [editFormData, setEditFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    city: ''
  });
  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
      id: Date.now(),
      ...addFormData,
    };
    const newContacts = [...contacts, newContact];
    setContacts(newContacts);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      id: editContactId,
      ...editFormData,
    };
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === editContactId);
    newContacts[index] = editedContact;
    setContacts(newContacts);
    setEditContactId(null);
  };

  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);
    const formValues = { ...contact };
    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts].filter((contact) => contact.id !== contactId);
    setContacts(newContacts);
  };

  return (
    <div>
      <form onSubmit={handleAddFormSubmit} className="add-form">
        <input type="text" name="fullName" required placeholder="Full Name" onChange={handleAddFormChange} />
        <input type="email" name="email" required placeholder="Email" onChange={handleAddFormChange} />
        <input type="text" name="phone" required placeholder="Phone Number" onChange={handleAddFormChange} />
        <input type="text" name="address" required placeholder="Address" onChange={handleAddFormChange} />
        <input type="text" name="city" required placeholder="City" onChange={handleAddFormChange} />
        <button type="submit">Add</button>
      </form>

      <form onSubmit={handleEditFormSubmit}>
        <table className="editable-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>City</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id}>
                {editContactId === contact.id ? (
                  <>
                    <td><input type="text" required name="fullName" value={editFormData.fullName} onChange={handleEditFormChange} /></td>
                    <td><input type="email" required name="email" value={editFormData.email} onChange={handleEditFormChange} /></td>
                    <td><input type="text" required name="phone" value={editFormData.phone} onChange={handleEditFormChange} /></td>
                    <td><input type="text" required name="address" value={editFormData.address} onChange={handleEditFormChange} /></td>
                    <td><input type="text" required name="city" value={editFormData.city} onChange={handleEditFormChange} /></td>
                    <td >
                      <button type="submit" style={{paddingRight: '10px'}}>Save</button>
                      <button type="button" onClick={handleCancelClick}>Cancel</button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{contact.fullName}</td>
                    <td>{contact.email}</td>
                    <td>{contact.phone}</td>
                    <td>{contact.address}</td>
                    <td>{contact.city}</td>
                    <td>
                      <button type="button" onClick={(event) => handleEditClick(event, contact)}>Edit</button>
                      <button type="button" onClick={() => handleDeleteClick(contact.id)}>Delete</button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default DynamicEditableTable;