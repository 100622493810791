import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
} from "@mui/material";
import "../css/addcolumn.css";
// import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { event } from "jquery";
import { faColumns } from "@fortawesome/free-solid-svg-icons";

// import { event } from "jquery";

const initialRows = [
  {
    SNo: "",
    atmId: "",
    atmName: "",
    createdAt: "",
  },
];

const AddnewColumn = () => {
  const [rows, setRows] = useState(initialRows);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from backend
        const response = await axios.get(
          // `https://192.168.0.108:3003/Add_ATMs`
          `https://thulasisolutions.in:5001/Add_ATMs`
        );
        const result = await response.data;
        console.log("Result--->", result.data);

        setRows(result.data); // Set data if fetched successfully
        console.log("Rows--->", rows);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setRows(initialRows); // Clear data on error
      }
    };

    fetchData();
  }, []);

  const [editRowId, setEditRowId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [newRow, setNewRow] = useState({
    SNo: "",
    atmId: "",
    atmName: "",
    createdAt: "",
  });

  const handleNewRowChange = (event, fieldName) => {
    setNewRow({ ...newRow, [fieldName]: event.target.value });
  };

  const handleEdit = (id) => {
    setEditRowId(id);
  };

  const handleSave = (id) => {
    setEditRowId(null);
  };

  const handleDelete = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleAddNewRow = () => {
    const newRowData = {
      id: rows.length + 1,
      // SNo: rows.length + 1,
      date: new Date().toLocaleDateString(),
      ...newRow,
    };
    setRows([...rows, newRowData]);
    setNewRow({ SNo: "", atmId: "", atmName: "", createdAt: "" });
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   console.log("Submitting table data:", rows);

  //   try {
  //     // Send the rows data to the backend
  //     const response = await axios.post(
  //       "https://192.168.0.108:3003/Add_ATMs",
  //       rows,
  //       {
  //         headers: {
  //           "Content-Type": "application/json", // Set content type to JSON
  //         },
  //       }
  //     );

  //     console.log("Data submitted successfully:", response.data);
  //     alert("Data submitted successfully!");
  //   } catch (error) {
  //     console.error("Error submitting data:", error);
  //     alert("Error submitting data. Please try again.");
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      for (let row of rows) {
        // const response = await axios.post("https://192.168.0.108:3003/Add_ATMs", row, {
        const response = await axios.post(
          "https://thulasisolutions.in:5001/Add_ATMs",
          row,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Data submitted successfully:", response.data);
      }
      alert("Submitted Data Successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Error submitting data. Please try again.");
    }
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm); // Toggle the visibility of the form
  };
  const handleDataSubmit = () => {
    alert("Successfully completed");
  };

  return (
    <div
      align="center"
      style={{
        paddingBottom: "2rem",
        // height: "100vh",
        width: "100%",
        // marginBottom:"2rem",
      }}
    >
      <div key={event.id} style={{ marginTop: "1rem", gap: "1rem" }}></div>
      <Grid style={{ width: "100%", height: "100%", paddingTop: "2rem" }}>
        {/* Conditional rendering of the form for adding a new row */}

        <form onSubmit={handleSubmit} style={{ width: "80%" }}>
          <TableContainer
            component={Paper}
            sx={{
              margin: "dense",
              size: "small",
              maxHeight: "590px",
              overflow: "auto",
              width: "100%",
            }}
          >
            <Table className="editable-table" style={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  {/* Table headers */}
                  <TableCell>S.No</TableCell>
                  <TableCell>ATM ID</TableCell>
                  <TableCell>ATM NAME</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Table body */}
                {rows.map((row, index) => (
                  // <TableRow key={row.id}>
                  <TableRow
                    key={row.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F0F0F0",
                      height: "10px",
                    }}
                  >
                    <TableCell style={{ height: "10px" }}>{row.id}</TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.atmId}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.atmName}
                    </TableCell>
                   
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <>
                          <Button
                            onClick={() => handleSave(row.id)}
                            color="primary"
                          >
                            Save
                          </Button>
                          <Button
                            onClick={() => handleDelete(row.id)}
                            color="secondary"
                          >
                            Delete
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button onClick={() => handleEdit(row.id)}>
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleDelete(row.id)}
                            color="secondary"
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "2rem",
            }}
          >
            {" "}
            {showForm && (
              <form
                style={{
                  width: "",
                  backgroundColor: "",
                  marginBottom: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <TextField
                  label="ATM ID"
                  variant="standard"
                  size="small"
                  value={newRow.atmId}
                  onChange={(event) => handleNewRowChange(event, "atmId")}
                  style={{}}
                />
                <TextField
                  label="ATM Name"
                  variant="standard"
                  size="small"
                  value={newRow.atmName}
                  onChange={(event) => handleNewRowChange(event, "atmName")}
                  style={{}}
                />

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddNewRow}
                >
                  Add
                </Button>
              </form>
            )}
            <Grid style={{ display: "flex", gap: "1rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleFormVisibility}
                style={{}}
              >
                {showForm ? "Hide New column" : "Add New column"}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{}}
                onClick={handleDataSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default AddnewColumn;
