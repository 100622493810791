import React, { useState } from "react";
import { Button } from "@mui/material";
import AddnewColumn from "./Addcolumn";
import AtmDataDisplay from "./ATMDataView";

function AddDataEntry() {
  const [showAddColumn, setShowAddColumn] = useState(false); 
  const [showData, setShowData] = useState(false); 

  const handleViewClick = () => {
    setShowData(!showData); // Toggle the visibility
  };

  const handleAddNewColumn = () => {
    setShowAddColumn(true); 
  };

  return (
    <div  align="center" style={{ marginTop: "1rem", gap: "1rem",marginBottom:"1rem" }}>
      {!showAddColumn ? (
        <>
          <Button
            style={{ backgroundColor: "#8A2387", color: "white", marginRight: "1rem" }}
            onClick={handleAddNewColumn} 
          >
            Adding
          </Button>
          <Button
        style={{ backgroundColor: "#8A2387", color: "white" }}
        onClick={handleViewClick}
      >
        {showData ? "Hide Data" : "View Data"} {/* Button Text */}
      </Button>

      {/* Conditionally Render AtmDataDisplay */}
      {showData && <AtmDataDisplay />}
        </>
      ) : (
        <AddnewColumn /> 
      )}
    </div>
  );
}

export default AddDataEntry;