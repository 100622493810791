import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";
import Sidebar from "./sidebar";
import "../css/sidebar.css";

interface Column {
  id: "agreement" | "rbi" | "incometax" | "otherdocs";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "agreement", label: "(DOCS)", minWidth: 80,align: "center", },
  { id: "rbi", label: "(DOCS)", minWidth: 80 ,align: "center",},

  {
    id: "incometax",
    label: "(DOCS)",
    minWidth: 100,
    align: "center",
    // format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "otherdocs",
    label: "(DOCS)",
    minWidth: 100,
    align: "center",
    // format: (value: number) => value.toLocaleString("en-US"),
  },
  //   {
  //     id: "statepersonal",
  //     label: "PERSONAL",
  //     minWidth: 100,
  //     // align: "center",
  //     // format: (value: number) => value.toFixed(2),
  //   },
  //   {
  //     id: "tdsbob",
  //     label: "BOB",
  //     minWidth: 100,
  //     // align: "center",
  //     // format: (value: number) => value.toFixed(2),
  //   },
  //   {
  //     id: "tdsub",
  //     label: "UB",
  //     minWidth: 100,
  //     // align: "center",
  //     // format: (value: number) => value.toFixed(2),
  //   },
  //   {
  //     id: "tdssbi",
  //     label: "SBI",
  //     minWidth: 100,
  //     // align: "center",
  //     // format: (value: number) => value.toFixed(2),
  //   },
  //   {
  //     id: "gst",
  //     label: "PAYMENT",
  //     minWidth: 100,
  //     // align: "center",
  //     // format: (value: number) => value.toFixed(2),
  //   },
  //   {
  //     id: "invoice",
  //     label: "PAYMENT",
  //     minWidth: 100,
  //     // align: "center",
  //     // format: (value: number) => value.toFixed(2),
  //   },
  //   {
  //     id: "download",
  //     label: "DOWNLOAD",
  //     minWidth: 80,
  //     // align: "center",
  //     // format: (value: number) => value.toFixed(2),
  //   },
];

interface Data {
  agreement: string;
  rbi: string;
  incometax: number;
  otherdocs: number;
  //   statepersonal: number;
  //   tdsbob: String;
  //   tdsub: String;
  //   tdssbi: String;
  //   gst: String;
  //   invoice: String;
}

function createData(
  agreement: string,
  rbi: string,
  incometax: string,
  otherdocs: string,
//   statepersonal: number,
//   tdsbob: String,
//   tdsub: String,
//   tdssbi: String,
//   gst: String,
//   invoice: String
): Data {
  const density = "";
  return {
    agreement,
    rbi,
    incometax,
    otherdocs,
    // statepersonal,
    // tdsbob,
    // tdsub,
    // tdssbi,
    // gst,
    // invoice,
  };
}

const rows = [
  createData("jan", "IN", 1324171354, 3287263),
  createData("Feb", "CN", 1403500365, 9596961),
  createData("Mar", "IT", 60483973, 301340),
  createData("Apr", "US", 327167434, 9833520),
  createData("May", "CA", 37602103, 9984670),
  createData("June", "AU", 25475400, 7692024),
  createData("July", "DE", 83019200, 357578),
  createData("Aug", "IE", 4857000, 70273),
  createData("Sep", "MX", 126577691, 1972550),
  createData("Oct", "JP", 126317000, 377973),
  createData("Nov", "FR", 67022000, 640679),
  createData("Dec", "GB", 67545757, 242495),
];

export default function Viewtable1() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Grid
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          //   backgroundColor:"red"
        }}
      >
        <br />
        <Paper sx={{ width: "95%" }}>
          <TableContainer style={{ height: "440px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={1}
                    style={{ backgroundColor: "yellow", fontWeight: "bold" }}
                  >
                    AGREEMENT
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={1}
                    style={{ backgroundColor: "yellow", fontWeight: "bold" }}
                  >
                    RBI AGREEMENT
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={1}
                    style={{ backgroundColor: "yellow", fontWeight: "bold" }}
                  >
                    INCOME TAX
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={1}
                    style={{ backgroundColor: "yellow", fontWeight: "bold" }}
                  >
                    OTHER DOCS
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ top: 57, minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        // role="checkbox"
                        // tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[6, 15, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
}
