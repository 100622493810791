import React from "react";
import { Grid, Typography } from "@mui/material";
// import { styled } from "@mui/material/styles";
import "../css/sidebar.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
const Sidebar = () => {
  return (
    <div>
      <Grid
        style={{ width: "100%", backgroundColor: "#365486", height: "100%" }}
      >
        <br />
        <a href="/newentry" style={{ textDecoration: "none" }}>
          <Typography id="typo">Admin</Typography>
        </a>
        <a href="/usertable" style={{ textDecoration: "none" }}>
          <Typography id="typo">User</Typography>
        </a>

        <a href="/atmService" style={{ textDecoration: "none" }}>
          <Typography id="typo">ATM Service</Typography>
        </a>
        <div className="dropdown" style={{}}>
          <Typography
            // className="btn btn-primary"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ width: "100%" }}
            id="typo"
          >
            Documents
          </Typography>
          <ul className="dropdown-menu" style={{ width: "100%" }}>
            <li>
              <a
                href="/monthlydocs"
                className="dropdown-item"
                type="button"
                id="typo"
              >
                Monthly Docs
              </a>
            </li>
            <li>
              <a
                href="/yearlydocs"
                className="dropdown-item"
                type="button"
                id="typo"
              >
                Yearly Docs
              </a>
            </li>
            <li>
              <a
                href="/viewcontent"
                className="dropdown-item"
                type="button"
                id="typo"
              >
                View Content
              </a>
            </li>
          </ul>
        </div>

        <a href="/" style={{ textDecoration: "none" }}>
          <Typography id="typo" style={{ marginTop: "100%" }}>
            Contact Us
          </Typography>
        </a>
        <a href="/" style={{ textDecoration: "none" }}>
          <Typography id="typo" style={{ }}>
            Logout
          </Typography>
        </a>
        <br />
      </Grid>
    </div>
  );
};

export default Sidebar;
