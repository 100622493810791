import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import CustomWebcam from "./webcam";
import Snackbar from "@mui/material/Snackbar";
import Autocomplete from "@mui/material/Autocomplete";

export default function ATMSERVICE() {
  const [atmData, setAtmData] = useState([]);
  const [dateTime, setDateTime] = useState(dayjs());
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isClear, setIsClear] = useState(false);

  const videoConstraints = {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    facingMode: "user",
  };

  const options = [{ atmName: "", atmId: "" }];
  const [selectedATM, setSelectedATM] = useState();

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching ATM data...");
      try {
        // const response = await axios.get(`https://192.168.0.108:3003/Add_ATMs`);
        const response = await axios.get(`https://thulasisolutions.in:5001/Add_ATMs`);
        const result = response.data; // Assuming the data is in result.data
        console.log("Fetched ATM data:", result.data);

        setAtmData(result.data); // Update atmData state
      } catch (error) {
        console.error("Error fetching ATM data:", error.message);
        setAtmData([]); // Set an empty array if there's an error
      }
    };

    fetchData();
  }, []);

  const handleChange1 = (event, value) => {
    setSelectedATM(value || { atmName: "", atmId: "" }); // Update selected ATM
    if (value) {
      setFormData({
        ...formData,
        atmId: value.atmId || "",
        atmName: value.atmName || "",
      });
    }
  };
  
  const initialFormdata = {
    atmId: "",
    atmName: "",
    date: dateTime,
    description: "",
    image1: "",
    image2: "",
    timestamp: "",
    locationName: "",
  };

  const [formData, setFormData] = useState(initialFormdata);
  const [resetWebcam, setResetWebcam] = useState(false);
  const uniqueRegions = [...new Set(atmData.map((atm) => atm.Region))];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateTimeChange = (newValue) => {
    setDateTime(newValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: dayjs(newValue).format("YYYY-MM-DD"),
      // date: newValue.format(),
      // date:new Date().toLocaleDateString()
    }));
  };

  const handlePhotoCapture1 = (photoData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image1: photoData.imageSrc,
      timestamp: photoData.timestamp,
      locationName: photoData.locationName,
    }));
  };

  const handlePhotoCapture2 = (photoData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image2: photoData.imageSrc,
      timestamp: photoData.timestamp,
      locationName: photoData.locationName,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("atm_id", formData.atmId);
      formDataToSubmit.append("atm_name", formData.atmName);
      formDataToSubmit.append("date", dateTime);
      console.log("Date---->", dateTime);
      formDataToSubmit.append("description", formData.description);
      formDataToSubmit.append("timestamp", formData.timestamp);
      formDataToSubmit.append("locationName", formData.locationName);
      if (formData.image1) {
        formDataToSubmit.append("image1", formData.image1);
      }
      if (formData.image2) {
        formDataToSubmit.append("image2", formData.image2);
      }
      // const response = await axios.post(
      //   "https://192.168.0.108:3003/atm_info",
        const response = await axios.post(
          "https://thulasisolutions.in:5001/atm_info",

        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Form submitted successfully:", response.data);
      if (response.status === 201) {
        alert("Form submitted successfully");
        handleReset();
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  const handleReset = () => {
    setFormData(initialFormdata);
    setSelectedATM({ atmId: "", atmName: "" });
    setDateTime(dayjs());
    setIsClear(true);
    setSnackbarOpen(true);

    // Reset webcams
    setResetWebcam(true);
    setTimeout(() => setResetWebcam(false), 100); // Reset the flag after triggering
  };

  return (
    <div align="center" style={{ width: "100%" }}>
      <Grid style={{ height: "100%" }}>
        <Typography
          style={{
            fontWeight: "bold",
            marginTop: "2rem",
            textDecoration: "underline",
          }}
          variant="h5"
        >
          ATM SERVICE FORM
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            style={{
              width: "30%",
              marginTop: "2rem",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Autocomplete
                disablePortal
                options={atmData} // Use fetched data
                clearOnEscape={isClear}
                value={selectedATM || null} // Ensure a default value is provided
                isOptionEqualToValue={(option, value) => option.atmId === value.atmId} // Compare IDs
                getOptionLabel={(option) => `${option.atmId} - ${option.atmName}`} // Adjust to match your data fields
                // getOptionLabel={(option) => `${option.atmId}`} // Adjust to match your data fields
                onChange={(event, value) => handleChange1(event, value)} // Handle selection changes
                renderInput={(params) => (<TextField {...params} label="ATM ID" />
                )}
              />

              {/* Display ATM name based on selection */}
              <TextField
                label="ATM Name"
                value={formData.atmName || ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Date & Time"
                  value={dateTime}
                  onChange={handleDateTimeChange}
                  fullWidth
                />
              </LocalizationProvider>
            </Grid>
            <Grid>
              <TextField
                multiline
                name="description"
                label="Description"
                rows={4}
                fullWidth
                value={formData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid justifyContent="center" alignItems="center" >
              {/* Camera for first image */}
              <CustomWebcam
                name="image1"
                videoConstraints={videoConstraints}
                onCapture={handlePhotoCapture1}
                reset={resetWebcam}
              />
              {formData.image1 && (
                <Typography variant="subtitle1">Image 1 slips </Typography>
              )}
              <br />
              {/* Camera for second image */}
              <CustomWebcam
                name="image2"
                videoConstraints={videoConstraints}
                onCapture={handlePhotoCapture2}
                reset={resetWebcam}
              />
              {formData.image2 && (
                <Typography variant="subtitle1">Image 2 cleaning</Typography>
              )}
            </Grid>
            <Grid>
              {formData.locationName && (
                <Typography variant="subtitle1">
                  Location: {formData.locationName}
                </Typography>
              )}
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              message="Successfully submitted the data!"
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            />
            <Button
              variant="contained"
              type="submit"
              style={{ marginBottom: "1rem" }}
            >
              UPLOAD
            </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}
