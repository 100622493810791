import ts from "../../assets/Ts.png";
const hai = {
  backgroundColor: "red",
  padding: "5px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Footer = () => {
  return (
    <div>
      <p style={hai}>
        <img style={{ height: "30px" }} src={ts} alt="company logo" />{" "}
        <span style={{ color: "white", fontWeight: "bold" }}>
          &emsp;Copyright @ 2023
        </span>
      </p>
    </div>
  );
};

export default Footer;
