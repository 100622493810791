import {
  AppBar,
  Button,
  Grid,
  useMediaQuery,
  Drawer,
  List,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  styled,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { useState } from "react";
import Full_logo from "../../assets/logo.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import PhoneIcon from "@mui/icons-material/Phone";
import CollectionsIcon from "@mui/icons-material/Collections";
import { useTheme } from "@mui/material/styles";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LoginIcon from "@mui/icons-material/Login";
import ListIcon from '@mui/icons-material/List';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import SpeedDial from "@mui/material/SpeedDial";
import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FitbitIcon from '@mui/icons-material/Fitbit';
// import SpeedDialAction from "@mui/material/SpeedDialAction";
// import GroupsIcon from "@mui/icons-material/Groups";
// import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
// import DesignServicesIcon from '@mui/icons-material/DesignServices';
//   import Short_logo from "../../images/logos/short_logo.png";
//   import "../CSS/common.css";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

export default function Appbar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const [direction, setDirection] = React.useState("down");
  const [social, setSocial] = React.useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isxsView = useMediaQuery(theme.breakpoints.down("xs"));
  const isCustomwidth = useMediaQuery(
    `(min-width:768px) and (max-width:1199px)`
  );
  const isSmview = useMediaQuery(`(min-width:0px) and (max-width:975px)`);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerWidth = 240;

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  }));

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 150,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  // State for the first dropdown
  const [anchorElOverview, setAnchorElOverview] = React.useState(null);
  const openOverview = Boolean(anchorElOverview);
  // console.log(openOverview);

  //temp
  // const [exapnd, setExapnd] = useState({ overview: false, getStarted: false });

  // State for the second dropdown
  const [anchorElGetStarted, setAnchorElGetStarted] = React.useState(null);

  // Event handlers for the first dropdown
  const handleClickOverview = (event) => {
    setAnchorElOverview(event.currentTarget);
  };
  const handleCloseOverview = () => {
    setAnchorElOverview(null);
  };

  const navigate = useNavigate();

  const appbarRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const appbar = appbarRef.current;
      if (appbar) {
        const rect = appbar.getBoundingClientRect();
        // If the top of the appbar is at or above the viewport, show the logo
        setSocial(rect.top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check scroll position on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const menuItems = [
    {
      text: "Home",
      icon: <HomeIcon />,
        onClick: () => {
          navigate("/");
          // setDrawerOpen(false);
        },
    },
    // {
    //   text: "About",
    //   icon: <InfoIcon />,
    //     onClick: () => {
    //       navigate("/Aboutuspage");
    //       setDrawerOpen(false);
    //     },
    // },
    {
      text: "ADD ATMS  ",
      icon: <ListIcon/> ,
        onClick: () => {
          navigate("/Addcolumn");
          setDrawerOpen(false);
        },
    },
    {
      text: "Indent Form",
      icon: <MiscellaneousServicesIcon />,
      onClick: () => {
        navigate("/Formuploaddata");
        // setDrawerOpen(false);
      },
    },
    // {
    //   text: "User",
    //   icon: <HandshakeIcon />,
    //   onClick: () => {
    //     navigate("/Indent_slip_form");
    //     // setDrawerOpen(false);
    //   },
    // },
    {
      text: "Form Uploads ",
      icon: <CollectionsIcon/>,
        onClick: () => {
          navigate("/AtmServiceData");
          // setDrawerOpen(false);
        },
    },
    // {
    //   text: "ADD ATMS  ",
    //   icon: <ListIcon/> ,
    //     onClick: () => {
    //       navigate("/Addcolumn");
    //       setDrawerOpen(false);
    //     },
    // },
    {
      text: "VIEW SLIPS",
      icon: <ViewModuleIcon/> ,
        onClick: () => {
          navigate("/viewslipform");
          setDrawerOpen(false);
        },
    },
  ];
  const action = [
    // {
    //   text: "Sign Up",
    //   icon: <SensorOccupiedIcon />,
    //   onClick: () => {
    //     navigate("/registrationform");
    //     setDrawerOpen(false);
    //   },
    // },
    {
      text: "Login",
      icon: <LoginIcon />,
      onClick: () => {
        navigate("/loginform");
        setDrawerOpen(false);
      },
    },
    {
      text: "Contact ",
      icon: <PhoneIcon />,
      //   onClick: () => {
      //     navigate("/Contactpage");
      //     setDrawerOpen(false);
      //   },
    },
  ];

  const socialmedia = [
    {
      icon: <LocalPhoneIcon />,
      name: "Phone",
      onClick: () => window.open(`tel:`),
    },
    {
      icon: <EmailIcon />,
      name: "Email",
      onClick: () => window.open("https://www.gmail.com/"),
    },
    {
      icon: <LocationOnIcon />,
      name: "Location",
      onClick: () =>
        window.open(
          "https://www.google.com/maps?ll=15.515882,80.038016&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=949064699632925230"
        ),
    },
    {
      icon: <FacebookIcon />,
      name: "Facebook",
      onClick: () => window.open("https://www.facebook.com/"),
    },
    {
      icon: <InstagramIcon />,
      name: "Instagram",
      onClick: () => window.open("https://www.instagram.com/"),
    },
  ];

  return (
    <>
      {/* <Grid
        container
        sx={{
          backgroundColor: "#007EC6",
          padding: "0.5rem",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{
            display: "flex",
            gap: "0.8rem",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "0.2rem",
          }}
        >
          <Link
            to={"mailto: "}
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography style={{ display: "flex", gap: "0.5rem" }}>
              {" "}
              <MailOutlineIcon /> Testmail@gmail.com
            </Typography>
          </Link>
          <Link to={"tel: "} style={{ textDecoration: "none", color: "white" }}>
            <Typography style={{ display: "flex", gap: "0.5rem" }}>
              <LocalPhoneOutlinedIcon /> +91 0000000000
            </Typography>
          </Link>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Grid
            className="iconsgrid"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Tooltip
              title="Locate in Google Maps"
              TransitionComponent={Zoom}
              arrow
            >
              <Link
                to="https://www.google.com/maps?ll=15.515882,80.038016&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=949064699632925230"
                target="_blank"
                style={{ padding: "5px", color: "white" }}
              >
                <LocationOnIcon />
              </Link>
            </Tooltip>
            <Tooltip title="Facebook" TransitionComponent={Zoom} arrow>
              <Link
                to="https://www.facebook.com"
                target="_blank"
                style={{ padding: "5px", color: "white" }}
              >
                <FacebookIcon />
              </Link>
            </Tooltip>
            <Tooltip title="Instagram" TransitionComponent={Zoom} arrow>
              <Link
                to="https://www.instagram.com"
                target="_blank"
                style={{ padding: "5px", color: "white" }}
              >
                <InstagramIcon />
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid> */}

      <AppBar
        position="sticky"
        // id="appbar"
        ref={appbarRef}
        sx={{
          width: "100%",
          height: "4rem",
          backgroundColor: "red",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <Grid sx={{ display: isSmview ? "none" : "block" }}>
          {" "}
          <Grid container className="appbar_lg">
            <Grid item lg={3} md={isCustomwidth ? 1 : 3} sm={1}>
              {isCustomwidth ? (
                <Grid>
                  <Link to="/">
                    {/* <img className="logo1" src={Short_logo} /> */}
                  </Link>
                </Grid>
              ) : (
                <Grid>
                  <Link to="/">
                    <img
                      className="logo2"
                      src={Full_logo}
                      style={{ height: "3rem" }}
                    />
                  </Link>
                  ,
                </Grid>
              )}
            </Grid>
            <Grid
              item
              lg={9}
              md={isCustomwidth ? 7.5 : 6}
              sm={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //   marginLeft:"1rem"
              }}
            >
              {menuItems.map((item, index) => (
                <Button
                  id="menu_button"
                  variant="text"
                  key={index}
                  onClick={item.onClick}
                  style={{
                    marginRight: "0.5rem",
                    // color: "#007EC6",
                    borderRadius: "20px",
                    // backgroundColor:"orange",
                    color: "white",
                    gap: "0.5rem",
                    padding: "0.5rem",
                    fontWeight: "bold",
                  }}
                  // color="inherit"
                >
                  {item.icon} {item.text}
                </Button>
              ))}
              <Tooltip title="Overview">
                <Button
                  onClick={handleClickOverview}
                  // endicon={<KeyboardArrowDownIcon />}
                  id="menu_button"
                  variant="text"
                  // key={index}
                  style={{
                    marginRight: "0.5rem",
                    // color: "#007EC6",
                    borderRadius: "20px",
                    // backgroundColor:"orange",
                    color: "white",
                    gap: "0.5rem",
                    padding: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  OVERVIEW {/* <KeyboardArrowDownIcon /> */}
                </Button>
              </Tooltip>

              <StyledMenu
                id="overview-menu"
                MenuListProps={{
                  "aria-labelledby": "overview-button",
                }}
                anchorEl={anchorElOverview}
                open={openOverview}
                onClose={handleCloseOverview}
              >
                <Link
                  to="/monthlydocs"
                  className="menuLinks"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem id="" onClick={handleCloseOverview} disableRipple>
                    {/* <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon> */}
                    Montly Docs
                  </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <Link
                  to="/yearlydocs"
                  className="menuLinks"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem id="" onClick={handleCloseOverview} disableRipple>
                    {/* <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon> */}
                    Yearly Docs
                  </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <Link
                  to="/viewcontent"
                  className="menuLinks"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem id="" onClick={handleCloseOverview} disableRipple>
                    {/* <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon> */}
                    View Docs
                  </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <Link
                  to="/excelReader"
                  className="menuLinks"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem id="" onClick={handleCloseOverview} disableRipple>
                    {/* <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon> */}
                    Excel Reader
                  </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <Link
                  to="/excelViewer"
                  className="menuLinks"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem id="" onClick={handleCloseOverview} disableRipple>
                    {/* <VolunteerActivismIcon id="menuItems_icon"></VolunteerActivismIcon> */}
                    Excel Viewer
                  </MenuItem>
                </Link>
              </StyledMenu>
            </Grid>
            {/* <Grid
              item
              lg={4}
              md={isCustomwidth ? 3.5 : 3}
              sm={3}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Grid style={{ marginRight: "5rem" }}>
                {action.map((item, index) => (
                  <Button
                    variant="contained"
                    key={index}
                    onClick={item.onClick}
                    style={{
                      marginRight: "1rem",
                    
                    }}
                   
                  >
                    {item.text}
                  </Button>
                ))}
              </Grid>
              {social ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    marginBottom: "5.5rem",
                    marginRight: "6rem",
                    boxShadow: "none",
                  }}
                >
                  <StyledSpeedDial
                    ariaLabel="SpeedDial playground example"
                    hidden={hidden}
                    sx={{ position: "absolute", boxShadow: "none" }}
                    icon={
                      <GroupsIcon
                        style={{
                          backgroundColor: "#007EC6",
                          padding: "1rem",
                          borderRadius: "100px",
                          border: "1px solid #007EC6",
                          boxShadow: "none",
                        }}
                      />
                    }
                    direction={direction}
                  >
                    {socialmedia.map((action) => (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                      />
                    ))}
                  </StyledSpeedDial>
                </Box>
              ) : (
                ""
              )}
            </Grid> */}
          </Grid>
        </Grid>
        <Grid sx={{ display: isSmview ? "block" : "none" }}>
          <Grid container className="appbar_sm">
            <Grid
              item
              sm={10}
              xs={10}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Link to="/">
                <img
                  // src={Full_logo}
                  style={{
                    width: "12rem",
                    height: "3.5rem",
                    marginLeft: "1rem",
                  }}
                />
              </Link>
            </Grid>
            <Grid
              item
              sm={2}
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton color="black" edge="end" onClick={handleDrawerToggle}>
                <MenuIcon aria-label="" />
              </IconButton>
            </Grid>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                },
              }}
              variant="temporary"
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              <DrawerHeader>
                <IconButton onClick={handleDrawerToggle}>
                  {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                {menuItems.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton onClick={item.onClick}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <List>
                {action.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton onClick={item.onClick}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}
