import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import "../css/newentry.css";
import Sidebar from "./sidebar";
import { useDispatch, useSelector } from "react-redux";
import  axios from "axios";
// import {useNavigate} from "react-router-dom"
// import { agenciesFetching } from "../../store/actions/fetch_counties_agencies";

const initialRows = [
  // Sample rows with added cash balance fields for demonstration
  {
    id: 1,
    date: new Date().toLocaleDateString(),
    atmId: "40416106",
    atmName: "Government",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  // {
  //   id: 2,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "40416105",
  //   atmName: "Kothapeta",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 3,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "40316011",
  //   atmName: "MGC",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 4,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "40316010",
  //   atmName: "Nehru Market",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 5,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "40316012",
  //   atmName: "Darbar Road",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 6,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "40416108",
  //   atmName: "Vodrev Road",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 7,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "40316013",
  //   atmName: "ITC",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 8,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "40294305",
  //   atmName: "Poltechnique col",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 9,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "40594304",
  //   atmName: "Suryalanka beach",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 10,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "60416109",
  //   atmName: "Vodrev 2.0",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
  // {
  //   id: 11,
  //   date: new Date().toLocaleDateString(),
  //   atmId: "60416109",
  //   atmName: "Vodrev 2.0",
  //   cash100: "",
  //   cash200: "",
  //   cash500: "",
  //   total: "",
  // },
];

const NewEntry = () => {
  const [rows, setRows] = useState(initialRows);
  const [editRowId, setEditRowId] = useState(null); 
  const [showForm, setShowForm] = useState(false); 
  // New state for adding a new row
  const [newRow, setNewRow] = useState({
    atmId: "",
    atmName: "",
    cash100: "",
    cash200: "",
    cash500: "",
  });

  // const agencies = useSelector((state) => state.agencyList.agency_data);
  // const submitted = useSelector((state) => state.countyList.county_Submitted);

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const handleClick = (item) => {
  //   navigate("/contact", { state: { data: item } });
  // };

  // const handleOnClick = async (CountyName, event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   dispatch(agenciesFetching());
  // };
  const handleNewRowChange = (event, fieldName) => {
    setNewRow({ ...newRow, [fieldName]: event.target.value });
  };

  const handleInputChange = (event, id, fieldName) => {
    const newValue = event.target.value;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [fieldName]: newValue };

        // Update total when cash values change
        if (["cash100", "cash200", "cash500"].includes(fieldName)) {
          const total = Object.keys(updatedRow)
            .filter((key) => key.startsWith("cash"))
            .reduce(
              (sum, key) => sum + (parseInt(updatedRow[key], 10) || 0),
              0
            );
          updatedRow.total = total.toString();
        }

        return updatedRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const handleEdit = (id) => {
    setEditRowId(id);
  };

  const handleSave = (id) => {
    setEditRowId(null); // Exit edit mode
  };

  const handleDelete = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleAddNewRow = () => {
    const newRowData = {
      id: rows.length + 1, // Simple ID assignment
      date: new Date().toLocaleDateString(),
      ...newRow,
      total:
        (parseInt(newRow.cash100, 10) || 0) +
        (parseInt(newRow.cash200, 10) || 0) +
        (parseInt(newRow.cash500, 10) || 0), // Calculate total
    };
    setRows([...rows, newRowData]);
    setNewRow({
      atmId: "",
      atmName: "",
      cash100: "",
      cash200: "",
      cash500: "",
    });
    // Reset form
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("Submitting table data:", rows);
    // dispatch(function1());
    try {
      // Submitting the form data
      const response = await axios.post(
        "https://localhost:3001/indendt_form",
        rows,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      console.log("Form submitted successfully:", response.data);
      // console.log(rows)
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm); // Toggle the visibility of the form
  };

  return (
    <div
      align="center"
      style={{
        paddingBottom: "2em",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        paddingBottom: "2rem",
        height: "100vh",
        width: "100%",
      }}
    >
      <Typography
        style={{
          fontWeight: "bold",
          marginTop: "1rem",
          // textDecoration: "underline",
        }}
        variant="h5"
      >
        INDENT UPLOAD FORM
      </Typography>
      <Grid style={{ width: "100%", height: "100%", paddingTop: "2rem" }}>
        {/* Conditional rendering of the form for adding a new row */}

        <form onSubmit={handleSubmit} style={{ width: "80%" }}>
          <TableContainer component={Paper}>
            <Table className="editable-table" style={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  {/* Table headers */}
                  <TableCell>Date</TableCell>
                  <TableCell>ATM ID</TableCell>
                  <TableCell>ATM NAME</TableCell>
                  <TableCell>100 Cash Balance</TableCell>
                  <TableCell>200 Cash Balance</TableCell>
                  <TableCell>500 Cash Balance</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Table body */}
                {rows.map((row, index) => (
                  // <TableRow key={row.id}>
                  <TableRow
                    key={row.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F0F0F0",
                      height: "10px",
                    }} // Alternating row colors
                  >
                    <TableCell style={{ height: "10px" }}>{row.date}</TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.atmId}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.atmName}
                    </TableCell>
                    {/* Editable cells */}
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash100}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash100")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash100
                      )}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash200}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash200")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash200
                      )}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash500}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash500")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash500
                      )}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.total}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <Button
                          onClick={() => handleSave(row.id)}
                          color="primary"
                        >
                          Save
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => handleEdit(row.id)}
                            color="primary"
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleDelete(row.id)}
                            color="secondary"
                          >
                            Delete
                          </Button>
                        </>
                      )}  
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "2rem",
            }}
          >
            {" "}
            {showForm && (
              <form
                style={{
                  width: "",
                  backgroundColor: "",
                  marginBottom: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                {/* Form fields */}
                <TextField
                  label="ATM ID"
                  variant="standard"
                  size="small"
                  value={newRow.atmId}
                  onChange={(event) => handleNewRowChange(event, "atmId")}
                  style={{}}
                />
                <TextField
                  label="ATM Name"
                  variant="standard"
                  size="small"
                  value={newRow.atmName}
                  onChange={(event) => handleNewRowChange(event, "atmName")}
                  style={{}}
                />
                <TextField
                  label="100 Cash Balance"
                  variant="standard"
                  size="small"
                  value={newRow.cash100}
                  onChange={(event) => handleNewRowChange(event, "cash100")}
                  style={{}}
                />
                <TextField
                  label="200 Cash Balance"
                  variant="standard"
                  size="small"
                  value={newRow.cash200}
                  onChange={(event) => handleNewRowChange(event, "cash200")}
                  style={{}}
                />
                <TextField
                  label="500 Cash Balance"
                  variant="standard"
                  size="small"
                  value={newRow.cash500}
                  onChange={(event) => handleNewRowChange(event, "cash500")}
                  style={{}}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddNewRow}
                >
                  Add
                </Button>
              </form>
            )}
            <Grid style={{ display: "flex",gap:"1rem" }}>
              {/* Button to toggle form visibility */}
              <Button
                variant="contained"
                color="primary"
                onClick={toggleFormVisibility}
                style={{}}
              >
                {showForm ? "Hide New Entry" : "Add New Entry"}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default NewEntry;
