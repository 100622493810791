const excel_Data = {
  info: [],
};

function Get_excel_Data(state = excel_Data, action) {
  if (action.type === "excelData") {
    return {
      ...excel_Data,
      info: action.payload,
    };
  }
  // console.log("11111", excel_Data.info);
  return excel_Data;
}

export default Get_excel_Data;
