import React, { useState } from "react";
import { Button, Modal, Box, Typography, Grid } from "@mui/material";
import Bankstatement from "./bankStatements";
import Sidebar from "./sidebar";
// import AddIcon from "@mui/icons-material/Add";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Documentform() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  // Removed the handleClose function from Modal's onClose to disable outside click to close
  const handleCloseButton = () => {
    setOpen(false); // Use this function for the close button inside the modal
  };
  //adding the bank statement
  // const [bankStatements, setBankStatements] = useState([]);
  // const newStatement = {
  //   id: Date.now(), // Simple unique ID for example purposes
  // };
  // const addBankStatement = () => {
  //   // Add a new Bankstatement component to the state
  //   setBankStatements([...bankStatements, {}]); // {} can be replaced with more specific data if needed
  // };
  // const deleteBankStatement = (id) => {
  //   setBankStatements(
  //     bankStatements.filter((statement) => statement.id !== id)
  //   );
  // };
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>

          <Button onClick={handleCloseButton} style={{ marginTop: "10px" }}>
            Close
          </Button>
        </Box>
      </Modal>
      <Grid style={{ display: "flex" }}>
       
        <Grid
          container
          style={{
            width: "85%",
            height: "100%",
            // backgroundColor: "lightgreen",
            gap: "20px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            // justifyContent: "center",
            paddingTop: "20px",
            alignItems: "center",
            padding:"20px"
          }}
        >
          <Grid style={{ display: "flex", gap: "20px" }}>
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ border: "1px solid black" }}
                id="typo"
              >
                Select Year
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a id="typo" href="" className="dropdown-item" type="button">
                    2023
                  </a>
                </li>
                <li>
                  <a id="typo" href="" className="dropdown-item" type="button">
                    2024
                  </a>
                </li>
                <li>
                  <a id="typo" href="" className="dropdown-item" type="button">
                    2025
                  </a>
                </li>
              </ul>
            </div>
            {/* <Grid
              container
              justifyContent="flex-end"
              style={{ marginBottom: "20px" }}
            >
              <Button variant="contained" >
                <AddIcon /> Add Bank Statement
              </Button>
            </Grid> */}
          </Grid>
          {/* <Grid>
            {bankStatements.map((statement, index) => (
              <Bankstatement
                key={statement.id}
                onDelete={() => deleteBankStatement(statement.id)}
                // You can pass other props to Bankstatement as needed
              />
            ))}
          </Grid> */}
          <Grid style={{ display: "flex", gap: "50px",flexWrap:"wrap" }}>
            <Grid
              item
              style={{
                height: "500px",
                backgroundColor: "lightblue",
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ backgroundColor: "#31304D", color: "white" }}
              >
                STATEMENTS
              </Typography>
              <Bankstatement />
            </Grid>
            <Grid
              item
              style={{
                height: "500px",
                backgroundColor: "orange",
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ backgroundColor: "#31304D", color: "white" }}
              >
                TDS EXCEMPTION
              </Typography>
              <Bankstatement />
            </Grid>
            <Grid
              item
              style={{
                height: "500px",
                backgroundColor: "lightyellow",
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ backgroundColor: "#31304D", color: "white" }}
              >
                GST PAYMENTS
              </Typography>
              <Bankstatement />
            </Grid>{" "}
            <Grid
              item
              style={{
                height: "500px",
                backgroundColor: "red",
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{ backgroundColor: "#31304D", color: "white" }}
              >
                INVOICE
              </Typography>
              <Bankstatement />
            </Grid>{" "}
            {/* this is the grid that is used yearly once while clicking the button */}
            <Grid
              style={{ backgroundColor: "white", display: "none", gap: "20px" }}
            >
              <Grid
                item
                style={{
                  height: "500px",
                  backgroundColor: "red",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  style={{ backgroundColor: "#31304D", color: "white" }}
                >
                  INVOICE
                </Typography>
                <Bankstatement />
              </Grid>{" "}
              <Grid
                item
                style={{
                  height: "500px",
                  backgroundColor: "red",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  style={{ backgroundColor: "#31304D", color: "white" }}
                >
                  INVOICE
                </Typography>
                <Bankstatement />
              </Grid>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Documentform;
