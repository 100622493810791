import React, { useState } from "react";
import {
  Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
  Paper,
  TextField,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../css/newentry.css";
import Sidebar from "./sidebar";
const initialRows = [
  // Sample rows with added cash balance fields for demonstration
  {
    id: 1,
    date: new Date().toLocaleDateString(),
    atmId: "40416106",
    atmName: "Government",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 2,
    date: new Date().toLocaleDateString(),
    atmId: "40416105",
    atmName: "Kothapeta",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 3,
    date: new Date().toLocaleDateString(),
    atmId: "40316011",
    atmName: "MGC",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 4,
    date: new Date().toLocaleDateString(),
    atmId: "40316010",
    atmName: "Nehru Market",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 5,
    date: new Date().toLocaleDateString(),
    atmId: "40316012",
    atmName: "Darbar Road",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 6,
    date: new Date().toLocaleDateString(),
    atmId: "40416108",
    atmName: "Vodrev Road",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 7,
    date: new Date().toLocaleDateString(),
    atmId: "40316013",
    atmName: "ITC",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 8,
    date: new Date().toLocaleDateString(),
    atmId: "40294305",
    atmName: "Poltechnique col",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 9,
    date: new Date().toLocaleDateString(),
    atmId: "40594304",
    atmName: "Suryalanka beach",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 10,
    date: new Date().toLocaleDateString(),
    atmId: "60416109",
    atmName: "Vodrev 2.0",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 11,
    date: new Date().toLocaleDateString(),
    atmId: "60416109",
    atmName: "Vodrev 2.0",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
  {
    id: 12,
    date: new Date().toLocaleDateString(),
    atmId: "60416109",
    atmName: "Vodrev 2.0",
    cash100: "",
    cash200: "",
    cash500: "",
    total: "",
  },
];

const DynamicEditableTable1= () => {
  const [rows, setRows] = useState(initialRows);
  const [editRowId, setEditRowId] = useState(null); // To track the row currently being edited
  const [showForm, setShowForm] = useState(false); // State to control the form visibility
  // New state for adding a new row
  const [newRow, setNewRow] = useState({
    atmId: "",
    atmName: "",
    cash100: "",
    cash200: "",
    cash500: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleInputChange = (event, id, fieldName) => {
    const newValue = event.target.value;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [fieldName]: newValue };

        // Update total when cash values change
        if (["cash100", "cash200", "cash500"].includes(fieldName)) {
          const total = Object.keys(updatedRow)
            .filter((key) => key.startsWith("cash"))
            .reduce(
              (sum, key) => sum + (parseInt(updatedRow[key], 10) || 0),
              0
            );
          updatedRow.total = total.toString();
        }

        return updatedRow;
      }
      return row;
    });
    setRows(newRows);
  };

  const handleEdit = (id) => {
    setEditRowId(id);
  };

  const handleSave = (id) => {
    setEditRowId(null); // Exit edit mode
  };

  const handleDelete = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleNewRowChange = (event, fieldName) => {
    setNewRow({ ...newRow, [fieldName]: event.target.value });
  };

  const handleAddNewRow = () => {
    const newRowData = {
      id: rows.length + 1, // Simple ID assignment
      date: new Date().toLocaleDateString(),
      ...newRow,
      total:
        (parseInt(newRow.cash100, 10) || 0) +
        (parseInt(newRow.cash200, 10) || 0) +
        (parseInt(newRow.cash500, 10) || 0), // Calculate total
    };

    setRows([...rows, newRowData]);
    setNewRow({
      atmId: "",
      atmName: "",
      cash100: "",
      cash200: "",
      cash500: "",
    }); // Reset form
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitting table data:", rows);
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm); // Toggle the visibility of the form
  };

  return (
    <div
      align="center"
      style={{
        // paddingTop: "2em",
        display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        width: "100%",
      }}
    >
      <Grid style={{ width: "15%" }}>
        <Sidebar />
      </Grid>

      <Grid style={{width:"85%",height:"100%"}}>
        {/* Button to toggle form visibility */}
        <Button
          variant="contained"
          color="primary"
          onClick={toggleFormVisibility}
          style={{ marginBottom: "20px",marginTop:"20px" }}
        >
          {showForm ? "Hide Add New Entry Form" : "Show Add New Entry Form"}
        </Button>

        {/* Conditional rendering of the form for adding a new row */}
        {showForm && (
          <form
            style={{ width: "80%", backgroundColor: "", marginBottom: "20px" }}
          >
            {/* Form fields */}
            <TextField
              label="ATM ID"
              variant="standard"
              value={newRow.atmId}
              onChange={(event) => handleNewRowChange(event, "atmId")}
              style={{ marginRight: "10px" }}
            />
            <TextField
              label="ATM Name"
              variant="standard"
              value={newRow.atmName}
              onChange={(event) => handleNewRowChange(event, "atmName")}
              style={{ marginRight: "10px" }}
            />
            <TextField
              label="100 Cash Balance"
              variant="standard"
              value={newRow.cash100}
              onChange={(event) => handleNewRowChange(event, "cash100")}
              style={{ marginRight: "10px" }}
            />
            <TextField
              label="200 Cash Balance"
              variant="standard"
              value={newRow.cash200}
              onChange={(event) => handleNewRowChange(event, "cash200")}
              style={{ marginRight: "10px" }}
            />
            <TextField
              label="500 Cash Balance"
              variant="standard"
              value={newRow.cash500}
              onChange={(event) => handleNewRowChange(event, "cash500")}
              style={{ marginRight: "10px" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddNewRow}
            >
              Add
            </Button>
          </form>
        )}
        <form onSubmit={handleSubmit} style={{ width: "80%" }}>
          <TableContainer component={Paper} style={{height:"470px"}}>
            <Table className="editable-table" style={{ width: "100%" }}>
            <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: "#BF3131" }}>
                Month
              </TableCell>
              <TableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                Status
              </TableCell>
              <TableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                Files
              </TableCell>
              <TableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                View
              </TableCell>
              {/* <TableCell  align="center">Pro</TableCell> */}
            </TableRow>
          </TableHead>
              <TableBody>
                {/* Table body */}
                {rows.map((row, index) => (
                  // <TableRow key={row.id}>
                  <TableRow
                    key={row.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F0F0F0",
                      height: "10px",
                    }} // Alternating row colors
                  >
                    {/* <TableCell style={{ height: "10px" }}>{row.date}</TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.atmId}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.atmName}
                    </TableCell> */}
                    {/* Editable cells */}
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash100}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash100")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash100
                      )}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash200}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash200")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash200
                      )}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash500}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash500")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash500
                      )}
                    </TableCell>
                    {/* <TableCell style={{ height: "10px" }}>
                      {row.total}
                    </TableCell> */}
                    <TableCell style={{ height: "10px" }}>
                      {editRowId === row.id ? (
                        <Button
                          onClick={() => handleSave(row.id)}
                          color="primary"
                        >
                          Save
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => handleEdit(row.id)}
                            color="primary"
                          >
                            Edit
                          </Button>
                          {/* <Button
                            onClick={() => handleDelete(row.id)}
                            color="secondary"
                          >
                            Delete
                          </Button> */}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Submit
          </Button>
        </form>

        {/* <TableContainer component={Paper} style={{ height: "470px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: "#BF3131" }}>
                Month
              </TableCell>
              <TableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                Status
              </TableCell>
              <TableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                Files
              </TableCell>
              <TableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                View
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
        
              <TableRow style={{ height: "10px" }}>
                <TableCell component="th" scope="row">
                  
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell  align="center">
            
                    </TableCell>
              </TableRow>
          
          </TableBody>
        </Table>
      </TableContainer> */}
      </Grid>
      <br/>
      <Grid>
      <TableContainer component={Paper} style={{ height: "470px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ backgroundColor: "#BF3131" }}>
                Month
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                Files
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#BF3131" }}
              >
                View
              </StyledTableCell>
              {/* <StyledTableCell  align="center">Pro</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
          {rows.map((row, index) => (
              <StyledTableRow  key={row.id}
              style={{
                backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F0F0F0",
               
              }}>
                <StyledTableCell component="th" scope="row">
                {editRowId === row.id ? (
                        <TextField
                          value={row.cash100}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash100")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash100
                      )}
                </StyledTableCell>
                <StyledTableCell align="center">
                {editRowId === row.id ? (
                        <TextField
                          value={row.cash200}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash200")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash200
                      )}

                </StyledTableCell>
                <StyledTableCell align="center">
                {editRowId === row.id ? (
                        <TextField
                          value={row.cash500}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash500")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash500
                      )}
                </StyledTableCell>
                
                {editRowId === row.id ? (
                        <Button
                          onClick={() => handleSave(row.id)}
                          color="primary"
                        >
                          Save
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => handleEdit(row.id)}
                            color="primary"
                          >
                            Edit
                          </Button>
                          {/* <Button
                            onClick={() => handleDelete(row.id)}
                            color="secondary"
                          >
                            Delete
                          </Button> */}
                        </>
                      )}
              </StyledTableRow>
        ))}
          </TableBody>
          <TableBody>
                {/* Table body */}
                {rows.map((row, index) => (
                  // <TableRow key={row.id}>
                  <StyledTableRow
                    key={row.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F0F0F0",
                     
                    }} // Alternating row colors
                  >
                    {/* <TableCell style={{ height: "10px" }}>{row.date}</TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.atmId}
                    </TableCell>
                    <TableCell style={{ height: "10px" }}>
                      {row.atmName}
                    </TableCell> */}
                    {/* Editable cells */}
                    <StyledTableCell style={{  }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash100}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash100")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash100
                      )}
                    </StyledTableCell>
                    <StyledTableCell style={{  }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash200}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash200")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash200
                      )}
                    </StyledTableCell>
                    <StyledTableCell style={{  }}>
                      {editRowId === row.id ? (
                        <TextField
                          value={row.cash500}
                          onChange={(event) =>
                            handleInputChange(event, row.id, "cash500")
                          }
                          variant="standard"
                        />
                      ) : (
                        row.cash500
                      )}
                    </StyledTableCell>
                    {/* <TableCell style={{ height: "10px" }}>
                      {row.total}
                    </TableCell> */}
                    <StyledTableCell style={{ }}>
                      {editRowId === row.id ? (
                        <Button
                          onClick={() => handleSave(row.id)}
                          color="primary"
                        >
                          Save
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => handleEdit(row.id)}
                            color="primary"
                          >
                            Edit
                          </Button>
                          {/* <Button
                            onClick={() => handleDelete(row.id)}
                            color="secondary"
                          >
                            Delete
                          </Button> */}
                        </>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
        </Table>
      </TableContainer>
      </Grid>
    </div>
  );
};

export default DynamicEditableTable1;
