import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";

const AtmDataDisplay = () => {
  const [data, setData] = useState([]); // State to store data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get("http://localhost:3002/api/get-data");
        const response = await axios.get("http://thulasisolutions.in:5001/api/get-data");
        setData(response.data); 
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <TableContainer component={Paper} style={{ marginTop: "20px" }}>
      <Typography variant="h4" align="center" style={{ margin: "20px 0" }}>
        ATM Data
      </Typography>

      {loading ? (
        <div style={{ textAlign: "center", margin: "20px" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <Table  margin="dense" size="small">
          <TableHead style={{backgroundColor:"blue",color:"white"}}>
            <TableRow style={{backgroundColor:"#2b5876"}}>
              <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>ATM ID</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>ATM Name</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length > 0 ? (
              data.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.atmId}</TableCell>
                  <TableCell>{item.atmName}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default AtmDataDisplay;
